import { lang } from '@/locales'
import qs from 'qs'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
const query = qs.parse(location.search.replace(/^\?(.*)/, '$1'))

const routes: Array<RouteRecordRaw> = [
  {
    path: '/index.html',
    name: 'YHT',
    component: () => import('@/views/YHT.vue'),
    meta: {
      title: '  ',
      backendTrackingParams: {
        pageId: 'index.html',
        pageType: 'index',
      }
    }
  },
  {
    path: '/order-list.html',
    name: 'OrderList',
    component: () => import('@/views/Wrapper.vue'),
    meta: {
      title: '  ',
      backendTrackingParams: {
        pageId: 'order-list.html',
        pageType: 'order_list',
        pageName: '订单列表页'
      }
    }
  },
  {
    path: '/order-detail.html',
    name: 'OrderDetail',
    component: () => import('@/views/OrderDetail.vue'),
    meta: {
      title: lang?.message?.order_detail?.title,
      backendTrackingParams: {
        pageId: query?.ikeaOrderNO,
        pageType: 'order_detail',
        pageName: '订单详情页'
      }
    }
  },
  {
    path: '/order-refund.html',
    name: 'OrderRefund',
    component: () => import('@/views/OrderRefund.vue'),
    meta: {
      title: lang?.message?.order_refund?.title,
      backendTrackingParams: {
        pageId: 'order-refund.html',
        pageType: 'OrderRefund',
      }
    }
  },
  {
    path: '/order-post-view.html',
    name: 'OrderPostView',
    component: () => import('@/views/OrderPostView.vue'),
    meta: {
      title: lang?.message?.order_post_view?.title,
      backendTrackingParams: {
        pageId: 'order-post-view.html',
        pageType: 'OrderPostView',
      }
    }
  },
  {
    path: '/aftersales-detail.html',
    name: 'AfterSalesDetail',
    component: () => import('@/views/AfterSalesDetail.vue'),
    meta: {
      title: lang?.message?.aftersales?.statusPage?.title,
      backendTrackingParams: {
        pageId: 'aftersales-detail.html',
        pageType: 'AfterSalesDetail',
      }
    }
  },
  {
    path: '/assembly-service.html',
    name: 'AssemblyService',
    component: () => import('@/views/AssemblyService.vue'),
    meta: {
      title: '',
      backendTrackingParams: {
        pageId: 'assembly-service.html',
        pageType: 'AssemblyService',
      }
    }
  },
  {
    path: '/track-and-trace.html',
    name: 'TrackAndTrace',
    component: () => import('@/views/TrackAndTrace.vue'),
    meta: {
      title: query.bizType === 'assembly'
        ? lang.message.track_and_trace.assembly.page.title
        : lang.message.track_and_trace.logistics.page.title,
      backendTrackingParams: {
        pageId: 'track-and-trace.html',
        pageType: 'TrackAndTrace',
      }
    }
  },
  {
    path: '/demo.html',
    name: 'Demo',
    component: () => import('@/views/Demo.vue'),
    meta: {
      title: 'DEMO',
      backendTrackingParams: {
        pageId: 'demo',
        pageType: 'demo',
        pageName: 'DEMO'
      }
    }
  },
  {
    path: '/wrapper.html',
    name: 'PcWeb',
    component: () => import('@/views/Wrapper.vue'),
    meta: {
      title: lang?.message?.order_list?.sources.online.text
    }
  },
  {
    path: '/order-list',
    name: '订单列表',
    component: () => import('@/views/Wrapper.vue'),
    meta: {
      title: '订单列表',
      backendTrackingParams: {
        pageId: 'order-list',
        pageType: 'order_list',
        pageName: '订单列表页'
      }
    }
  },
  {
    path: '/order-detail',
    name: '订单详情',
    component: () => import('@/views/OrderDetail.vue'),
    meta: {
      title: '订单详情',
      backendTrackingParams: {
        pageId: query?.ikeaOrderNO,
        pageType: 'order_detail',
        pageName: '订单详情页'
      }
    }
  },
  {
    path: '/order-refund',
    name: '退款详情',
    component: () => import('@/views/OrderRefund.vue'),
    meta: {
      title: '退款详情',
      backendTrackingParams: {
        pageId: 'order-refund',
        pageType: 'OrderRefund',
      }
    }
  },
  {
    path: '/aftersales-detail',
    name: '售后状态',
    component: () => import('@/views/AfterSalesDetail.vue'),
    meta: {
      title: '售后状态',
      backendTrackingParams: {
        pageId: 'aftersales-detail',
        pageType: 'AfterSalesDetail',
      }
    }
  },
  {
    path: '/assembly-service',
    name: '服务预约',
    component: () => import('@/views/AssemblyService.vue'),
    meta: {
      title: '',
      backendTrackingParams: {
        pageId: 'assembly-service',
        pageType: 'AssemblyService',
      }
    }
  },
  {
    path: '/track-and-trace',
    name: '物流详情',
    component: () => import('@/views/TrackAndTrace.vue'),
    meta: {
      title: '',
      backendTrackingParams: {
        pageId: 'track-and-trace',
        pageType: 'TrackAndTrace',
      }
    }
  },
]
// let base = CHANNEL_PUBLIC_PATH
// eslint-disable-next-line no-undef
let base = '/'
if (window.injectProperty && window.injectProperty.prerender || import.meta.env.MODE === 'dev') {
  base = ''
}
if (window.__IKEACN__) {
  base = `/cn/${window.__IKEACN__.app.getContext().getLang()}/${window.__IKEACN__.app.getContext().getContextPath()}/order`
}

const router = createRouter({
  history: createWebHistory(base),
  routes
})

export default router
