<template>
  <van-button
    :type="type"
    :size="size"
    :plain="plain"
    :color="color"
    :round="round"
    @click="onClick"
    :block="false"
  >
    <slot></slot>
  </van-button>
</template>

<script>
import { defineComponent } from 'vue'
import { Button } from 'vant'

export default defineComponent({
  components: {
    VanButton: Button
  },
  props: {
    type: {
      type: String,
    },
    size: {
      type: String,
    },
    color: {
      type: String,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: true,
    },
    onClick: {
      type: Function
    }
  },
  setup() {
    return {
    }
  },
})
</script>

<style scoped>
.van-button {
  --van-button-primary-background-color: #0058a3;
  --van-button-primary-border-color: #0058a3;
  --van-button-default-font-size: 14px;
  --van-button-default-height: 40px;
}
</style>
