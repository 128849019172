export enum AfterSalesServiceType_Enums {
  RPA_CANCEL = 'RPA_CANCEL',
  SELF_SERVICE_EXCHANGE = 'SELF_SERVICE_EXCHANGE',
  SELF_SERVICE_RETURN = 'SELF_SERVICE_RETURN',
}

export enum AfterSalesStatus_Enums {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}
