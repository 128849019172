import { lang } from '@/locales'
export const MEDIA_WIDTH = 740

export enum OrderChannel_Enums {
  COMPOSITE = 'COMPOSITE',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  STORE_COMPANION = 'STORE_COMPANION' //  scan&go特定商城订单
}

export enum OrderStatus_Enums {
  WAITING_FOR_PAYMENT = 'Unpaid',
  WAITING_FOR_SERVICE = 'Service',
  WAITING_FOR_DELIVERY = 'Processing',
  WAITING_FOR_RECEIVE = 'Shipped',
  WAITING_FOR_POSTREVIEW = 'Review',
  WAITING_FOR_PICKUP = 'Picking Up',
  COMPLETED = 'Completed',
  CANCELED = 'Cancelled',
  CANCELLED = 'Cancelled',
  COLLECTED = 'Collected',
}

export enum EOrderStatus {
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  COLLECTED = 'COLLECTED',
  ALL = 'ALL'
}

/** 订单列表 */
// 订单状态
export const ORDER_CATEGORY_ENUMS_LIST = [
  {
    type: EOrderStatus.ALL,
    titleKey: 'message.order_list.cates.all',
    name: lang.message.order_list.cates.all,
    key: EOrderStatus.ALL,
  },
  {
    type: EOrderStatus.WAITING_FOR_PAYMENT,
    titleKey: 'message.order_list.cates.waiting_for_payment',
    name: lang.message.order_list.cates.waiting_for_payment,
    key: EOrderStatus.WAITING_FOR_PAYMENT,
  },
  {
    type: EOrderStatus.PAID,
    titleKey: 'message.order_list.cates.waiting_for_delivery',
    name: lang.message.order_list.cates.waiting_for_delivery,
    key: EOrderStatus.PAID,
  },
  {
    type: EOrderStatus.DELIVERED,
    titleKey: 'message.order_list.cates.waiting_for_receive',
    name: lang.message.order_list.cates.waiting_for_receive,
    key: EOrderStatus.DELIVERED,
  },
  {
    type: EOrderStatus.COMPLETED,
    titleKey: 'message.order_list.cates.waiting_for_postreview',
    name: lang.message.order_list.cates.waiting_for_postreview,
    key: EOrderStatus.COMPLETED,
  },
  {
    type: 'AFTER_SALES',
    titleKey: 'message.order_list.cates.after_sales',
    name: lang.message.order_list.cates.after_sales,
    key: 'AFTER_SALES',
  }
]

// 订单类型（online&offline）
export const ORDER_TITLE_ENUMS = [
  {
    type: OrderChannel_Enums.COMPOSITE,
    titleKey: 'message.order_list.sources.all.text',
    icon: 'icon-composite',
    checkedIcon: 'icon-composite-checked'
  },
  {
    type: OrderChannel_Enums.ONLINE,
    titleKey: 'message.order_list.sources.online.text',
    icon: 'icon-online',
    checkedIcon: 'icon-online-checked'
  },
  {
    type: OrderChannel_Enums.OFFLINE,
    titleKey: 'message.order_list.sources.offline.text',
    icon: 'icon-offline',
    checkedIcon: 'icon-offline-checked'
  },
  {
    type: OrderChannel_Enums.STORE_COMPANION,
    titleKey: '',
    icon: '',
    checkedIcon: ''
  }
]

/** 订单详情 */

export enum OrderFrom_Enums {
  ORDER_LIST = 'ORDER_LIST', // 订单列表
  ORDER_DETAIL = 'ORDER_DETAIL', // 订单详情
  ORDER_REVIEW = 'ORDER_REVIEW' // 待晒单
}

// 取消已支付订单的错误码
export const CANCEL_ORDER_ERROR = {
  // https://confluence.build.ingka.ikea.com/pages/viewpage.action?pageId=582846003
  ERROR_SEND_REQUEST_RPA: 'error_send_request_RPA',
  ERROR_REVOKE_SYSTEM_ERROR: 'error_revoke_system_error',
  ERROR_REVOKE_BUSINESS_ERROR: 'error_revoke_business_error',
  ERROR_DUPLICATE_CANCEL: 'error_duplicate_cancel',
  ERROR_CANCEL_TIMEOUT: 'error_cancel_timeout',
  INVALID_PROPOSAL_STATUS: 'invalid_proposal_status',
  ERROR_IB_ORDER_REBATE: 'error_ib_order_rebate',
}

export enum InvoiceStatus_Enums {
  NOT_INVOICED = 0,
  HAS_INVOICED = 2,
  ROUNDING = 3, // https://jira.digital.ingka.com/browse/CNODI-1972
}

// 页面的状态
export enum PageStatus_Enums {
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
  EMPTY = 'empty'
}

export enum OrderRefundStatus_Enums {
  SUCCESS = 'REFUND_COMPLETED',
  PROCESSION = 'REFUND_PROCESSING',
  FAIL = 'REFUND_FAILED'
}
