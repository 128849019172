export default {
  title: '退款成功',
  refundResult: '退款成功',
  refundResultDesc: '订单金额已退回你的账号',
  totalAmount: '退款金额',
  time: '退款时间',
  route: '原路退还账号',
  orderNo: '订单号',
  errorMsg: '加载失败，点击重试',
  multiRecordsNotice: '当前订单包含多笔退款记录',
}
