
import { PageStatus_Enums } from '@/enums/order'
import { AfterSalesStatus_Enums, AfterSalesServiceType_Enums } from '@/enums/afterSales'

const getDefaultState = () => {
  return {
    status: PageStatus_Enums.LOADING,
    refundData: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateStatus(state, payload) {
      state.status = payload
    },
  },
  actions: {
    /**
     * @description 获取退款详情
     * @param orderNo 订单编号
     */
    fetchAfterSalesData({ state, commit }, payload) {
      const { afterSalesStatus, afterSalesServiceType } = payload
      if (
        AfterSalesStatus_Enums[afterSalesStatus] === undefined || 
        AfterSalesServiceType_Enums[afterSalesServiceType] === undefined
      ) {
        commit('updateStatus', PageStatus_Enums.ERROR)
      } else {
        if (afterSalesServiceType === AfterSalesServiceType_Enums.RPA_CANCEL) {
          commit('updateStatus', PageStatus_Enums.ERROR)
        } else {
          commit('updateStatus', PageStatus_Enums.SUCCESS)
        }
      }
    }
  }
}
