export default {
  title: '选择配送时间',
  select: '选择时间段',
  selected: '已选 {period}',
  rule: {
    name: '改期规则',
    title: '改期规则',
  },
  action_sheet: {
    before_sorting: {
      title: '订单分拣前',
      desc: '可在订单详情页自助修改配送日期。（以实际操作结果为准）',
    },
    after_sorting: {
      title: '订单分拣后',
      desc: '若仍需改期，可修改至配送日期后七天内的日期，请于原配送日期前一天联系客服更改（具体以您与宜家客服商定的日期为准)。宜家不承担产品仓储责任，若超期未送达，宜家将为您退款处理，敬请谅解。',
    }
  },
  status: {
    success: '修改成功',
    failed: '修改失败'
  }
}
