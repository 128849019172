import orderActions from './orderActions'
export default {
  title: '你可能喜欢',
  end: '没有更多推荐啦，让小宜 FIKA 一下',
  addProductsToShoppingCart: {
    not_support_online_sales: '暂不支持线上购买',
    warning_tips: '不小心发生错误了，请点击刷新',
    out_of_stock: '暂无库存',
    actions: {
      ...orderActions,
      goToCart: '查看 >',
    },
    status: {
      success: '已添加至购物袋',
      successWithCount: '{count} 件商品已加入购物袋',
      failed: '对不起，加入到购物袋失败，请稍后再试。'
    }
  }
}
