// import fetchTimeWindowData from '@/mock/fetchTimeWindow'
import http from '@/utils/http'

const fetchTimeWindow = async(params) => {
  // console.log(params)
  // const res = fetchTimeWindowData;
  // console.log('res....', fetchTimeWindowData);
  // return res
  return await http.get('FETCH_TIME_WINDOW', {
    params: {
      orderNo: params.orderNO,
      deliveryId: params.deliveryId,
    }
  }).then((res) => {
    // mock
    // res.data.data.timeWindowByDate[0].timeWindowList.push({
    //   changeTimeWindowLink: "https://isom-order-modification-prod-selling-api.ocp-02.ikea.com/change-delivery-timewindow/eyJhbGciOiJIUzUxMiJ9.eyJvcmRlck5vU291cmNlIjoiQTA0Iiwib3JkZXJObyI6MTQyNTk5NjUxLCJyZXNvdXJjZVBvb2xJZCI6InR3OFVyQ0lKVjBTd2NnLzdBS1hhRUE9PSIsInRvTG9jYWxEYXRlVGltZSI6IjIwMjEtMDktMDNUMTc6MDA6MDAiLCJmcm9tTG9jYWxEYXRlVGltZSI6IjIwMjEtMDktMDNUMTM6MDA6MDAiLCJ0aW1lV2luZG93SWQiOiIzNTM2YzRkMS0zMjU1LTRkMjYtOWMzZC0xMDg5MDBkNTE5MDYiLCJkZWxpdmVyeU5vIjoiNDYiLCJ0aW1lWm9uZSI6IkFzaWEvU2hhbmdoYWkiLCJleHAiOjE2MzAzMDE3NTN9.6AuH7Kjp3ayux_8HZxFj6LMtrKTOU5mubqnkVBTFUwxAzDZ14RqfUVWJHjYpd0BfkDKOtjgZue3pE_lzjxXKjQ",
    //   fromLocalDateTime: "2021-09-03T21:00:00",
    //   timeZone: "Asia/Shanghai",
    //   toLocalDateTime: "2021-09-03T23:00:00",
    // })
    console.log('fetchTimeWindow res:', res.data)
    return res.data && res.data || {}
  }).catch(async err => {
    console.log('fetchTimeWindow err:', err)
    return new Error(JSON.stringify(err))
  })
}

const changeTimeWindow = async(params) => {
  return await http.post('CHANGE_TIME_WINDOW', {
    twId: params.twId,
    // changeTimeWindowLink: params.changeTimeWindowLink,
    deliveryId: params.deliveryId,
    fromLocalDateTime: params.fromLocalDateTime,
    orderNo: params.orderNo,
    orderNoSrc: params.orderNoSrc,
    toLocalDateTime: params.toLocalDateTime,
  }).then((res) => {
    console.log('changeTimeWindow res:', res)
    return res.data && res.data || {}
  }).catch(async err => {
    console.log('changeTimeWindow err:', err)
    return new Error(JSON.stringify(err))
  })
}

function rescheduleService() {
  return {
    fetchTimeWindow,
    changeTimeWindow,
  }
}

export default rescheduleService()
