<template>
  <ik-plain-order-status
    v-if="mode === 'plain'"
    :debug="debug"
    :status="status"
    :remain="remain"
    :deliveryOption="deliveryOption"
    :hasReviewed="hasReviewed"
    :hasExceededReviewTimeLimit="hasExceededReviewTimeLimit"
    :isSimpleServiceOrder="isSimpleServiceOrder"
  />
  <ik-rich-order-status
    v-if="mode === 'rich'"
    :debug="debug"
    :status="status"
    :remain="remain"
    :deliveryOption="deliveryOption"
    :hasReviewed="hasReviewed"
    :hasExceededReviewTimeLimit="hasExceededReviewTimeLimit"
    :isSimpleServiceOrder="isSimpleServiceOrder"
  >
    <template #price v-if="slots.price">
      <slot name="price"></slot>
    </template>
    <template #extras v-if="slots.extras">
      <slot name="extras"></slot>
    </template>
  </ik-rich-order-status>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import RichOrderStatus from './rich.vue'
import PlainOrderStatus from './plain.vue'

export default defineComponent({
  props: {
    debug: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'WAITING_FOR_PAYMENT'
    },
    remain: {
      type: Number,
      default: 0
    },
    deliveryOption: {
      type: String,
      default: ''
    },
    hasReviewed: {
      type: Boolean,
      default: false,
    },
    hasExceededReviewTimeLimit: {
      type: Boolean,
      default: false,
    },
    isSimpleServiceOrder: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'plain'
    }
  },
  components: {
    IkRichOrderStatus: RichOrderStatus,
    IkPlainOrderStatus: PlainOrderStatus,
  },
  setup(props, context) {
    props = reactive(props);

    const slots = context.slots;

    return {
      slots,
    }
  },
})
</script>
