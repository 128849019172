import reschedule from './reschedule'

const types = {
  'parcel_delivery': 'Parcel Delivery',
  'express': 'Express Delivery',
  'home_delivery': 'Standard Delivery',
  'truck_delivery': 'Truck Delivery',
  'parcel_pickup': 'Click and Collect',
  'standard_rd': 'Range of Days',
  'carry': 'Collect At Store',
  'delivery': 'Home Delivery',
}

const steps = {
  INIT: {
    text: 'Ordered',
    description: 'Your order has been received and the shopping mall is preparing for the goods'
  },
  PREPARING: {
    text: 'Preparing',
    description: 'Shopping malls are actively preparing goods for you, and you will receive SMS notice after the goods are prepared.'
  },
  AWAITING_COLLECTION: {
    text: 'Shipped'
  },
  COMPLETED: {
    text: 'Completed'
  }
}

const option = {
  PUOP: {
    name: 'Locker',
    tips: {
      a: 'Available for pickup in 30 minutes',
      b: 'Please pay attention to SMS',
      c: 'View locker location'
    },
    scanGoTips: {
      a: 'Please go to the store locker with',
      b: 'the message sent by IKEA',
    },
    steps: {
      INIT: steps.INIT,
      PREPARING: {
        ...steps.PREPARING,
        text: 'Preparing',
      },
      AWAITING_COLLECTION: {
        ...steps.AWAITING_COLLECTION,
        text: 'Collecting',
        description: 'All set! Please be aware that pickup notification has been sent to your mobile phone. Please complete the pickup <span style="color: #FDB650;">before 22:00</span> on the day. Thank you for your understanding and cooperation!'
      },
    }
  },
  CLICK_COLLECT_STORE: {
    name: 'Click and Collect',
    location: 'Click and Collect',
    actions: {
      addr_nav: 'Store Address',
      store_info: 'Store Information',
      warehouse_info: 'Location Information',
    },
    steps: {
      INIT: {
        ...steps.INIT,
      },
      PREPARING: {
        ...steps.PREPARING,
        text: 'Preparing',
      },
      AWAITING_COLLECTION: {
        ...steps.AWAITING_COLLECTION,
        text: 'Collecting',
        description: 'All set! Please be aware that pickup notification has been sent to your mobile phone. Please come to pickup your order within 24hrs after receiving your SMS notification.  Exceeding 48hrs will be considered as order cancellation. Thank you for your understanding and cooperation!'
      },
      COMPLETED: {
        ...steps.COMPLETED,
        text: 'Completed',
        description: 'The pickup of the order was completed at {actualDeliveryDateTime} {pickupPoint}'
      }
    },
    htmlTips: 'At IKEA, our product price does not include packaging or delivery cost. We provide packaging material in our local stores and <strong>please bring a carrying bag with you when picking up your products.</strong>'
  },
  HOME_DELIVERY: {
    STANDARD_RD: {
      name: 'Range Of Days',
      steps: {
        INIT: {
          ...steps.INIT,
          description: ''
        },
        PREPARING: {
          ...steps.PREPARING,
          description: 'Our co-worker will contact you by phone or SMS 24H before the deliver date to confirm the specific deliver time. This deliver method can not be rescheduled online, if you want to cancel the order, please contact with customer service.'
        },
        AWAITING_COLLECTION: {
          ...steps.AWAITING_COLLECTION,
          text: 'Shipped',
          description: 'Our co-worker will contact you by phone or SMS 24H before the deliver date to confirm the specific deliver time. This deliver method can not be rescheduled online, if you want to cancel the order, please contact with customer service.'
        },
        COMPLETED: {
          ...steps.COMPLETED,
          description: 'Estimated delivery time {deliveryFromTime} - {deliveryTime}'
        }
      }
    }
  },
  default: {
    steps,
    store_entrance_label: 'Welcome to explore IKEA Store'
  }
}

export default {
  title: 'Order Details',
  tips: 'Your package will be distributed in {batches} batches',
  gitTips: 'Reminder: Some products in the order need to be specially transferred. The order will be delivered together afterward. Please wait patiently.',
  types,
  actual_arrive_time: 'Delivery Time',
  arrived: 'Your package has been delivered',
  expected_delivery_time: 'Delivery Time',
  expected_arrive_time: 'Delivery Time',
  edit: 'Modify',
  option,
  reschedule,
  temp_epidemics_desc: 'Due to the impact of the epidemic, we are currently experiencing delays which could impact your delivery date in some regions. Please check the shipment details for more information',
  parcel_delay: {
    desc: 'The delivery time is slightly delayed because some products in the package need to be specially transferred for you. Please wait patiently, thanks!',
    cs: 'Customer Service',
  },
}
