export default {
  bookingBefore: {
    headTitle: 'Book IKEA assembly service',
    headDesc: 'Professional and Reliable, Know more about IKEA assembly services',
    bannerTitle: 'Select products that need the service',
    bannerDesc: 'After submission, customer service will call you back to confirm the assembly service price and service time'
  },
  bookingAfter: {
    pageTitle: 'Successfully Applied ',
    headTitle: 'IKEA assembly service appointment request has been submitted',
    headDesc: 'Customer service will contact you as soon as possible, please keep the phone available.',
    bannerTitle: 'Products requiring assembly services',
  },
  priceTip: {
    a: 'Total estimated assembly service costs',
    b: 'The installation cost of {payload} pieces is not included (such as electricity, wall mounting, etc.)'
  },
  comments: {
    title: 'Additional cost: Basic service cost ¥{payload}',
    detail: 'Understand the charge details'
  },
  btnText: 'Submit a request',
  checkBoxLabel: 'Select All',
  detailBanner: {
    before: {
      title: 'Book IKEA assembly service',
      desc: 'Get your items unboxed and assembled',
      diffcultDesc: 'Assembly of {assemblyDifficultCnt} product is relatively difficult. We recommend opting for assembly service'
    },
    after: {
      title: 'IKEA assembly Service Booking',
      actions: {
        my_assembly_orders: 'My appointment',
        assembly_detail: 'Service introduction'
      }
    },
  },
  childItemCntLabel: 'Contains {payload} items',
  bookingsubmit: {
    status: {
      processing: 'Booking...',
      success: 'Booking successful'
    }
  },
  prodCard: {
    servicePriceDesc: {
      wallAnchoringService: 'Wall anchoring service',
      curtainRoleAnchoringService: 'Curtain role anchoring service',
      electricityInstallationService: 'Electricity installation service',
      bathroomInstallationService: 'Bathroom installation service',
      kitchenInstallationService: 'Kitchen installation service',
      slidingDoorInstallationService: 'Sliding door installation service',
      default: 'Assembly service cost'
    },
    priceDesc: 'Assembly service cost ',
    diffcultDesc: 'Assembly of this product is relatively difficult. We recommend opting for assembly service',
    noPriceDesc: 'Considered based on customer service calculations'
  },

  before: {
    title: 'Book IKEA Assembly Service',
    desc: 'Some of your purchases come in sections. You can assemble them yourself, or choose from our paid assembly services',
    support: 'Assembly Service',
  },
  after: {
    title: 'IKEA assembly Service Booking',
    actions: {
      my_assembly_orders: 'Booking Management',
      assembly_detail: 'Service Detail'
    }
  },
  booking: {
    title: 'Booked IKEA Assembly Service',
    ikea: 'IKEA Service',
    master_wan: 'Master Wan Service',
    actions: {
      booking_ikea_service: 'Book IKEA Assembly Service',
      booking_service: 'Book assembly service'
    },
    status: {
      processing: 'Booking...',
      success: 'Booking successful'
    }
  },
  booked: {
    title: 'Booking finished',
    desc: 'You have booked an IKEA assembly service for the items below. Our Customer service will be in touch soon.'
  },
  patService: {
    netAmount: 'Assembly service cost',
    bookedServiceTitle: 'Booked IKEA assembly service',
    expectedTitle: 'Estimated assembly service time',
    selectedTitle: 'Selected products that need to be assembled',
    installationFee: 'Installation fee',
    basicFee: 'Basic service fee ',
    moreInfo: 'More information',
    includes: '{childItemsCount} items',
    status: {
      CREATED: 'Waiting for service',
      PROCESSED: 'Waiting for service',
      CANCELED: 'Service cancelled',
      COMPLETED: 'Service completed',
    }
  },
  vendors: {
    ikea: [
      {
        title: 'Directions of assembly fee:',
        desc: 'The service is provided by an IKEA service provider'
      }, {
        title: 'Professional Assembly Time',
        desc: '59CNY+'
      }, {
        title: 'Calculation Method',
        desc: 'Based on Predicted Assembly Time and complexity provided by IKEA of Sweden, multiple by 2 CNY per minute (except for some range), then add 49 CNY base price. Predicted Assembly Time is a medium number, excludes Wall Mounting time.'
      }, {
        title: '',
        desc: 'The presented “59 CNY +” is a reference price for IKEA products with Predicted Assembly Time of 5 minutes or more, wall mounting price excluded.'
      },
    ],
    wan: [
      {
        title: 'Self-service appointment process:',
        desc: 'You may place assembly service order via platform “Wanshifu (万师傅)” which is our IKEA cooperation partner. It covers furniture assembly service only.',
      },
      {
        title: 'The first step',
        desc: 'Complete the payment'
      },
      {
        title: 'The second step',
        desc: 'Open “Wanshifu (万师傅)“ WeChat Mini program and find "IKEA Customer Furniture Assembly Service"',
        qrcode: 'Scan the QR code on the left, or search for \'Wanshifu Family Edition\' in the WeChat mini program.'
      },
      {
        title: 'The third step',
        desc: 'Add the furniture that needs to be assembled and publish the task.',
      },
      {
        title: 'The fourth step',
        desc: 'Select a worker and pay the fee.'
      },
    ],
    actions: {
      more: 'Detailed service and charge information',
    }
  },
  rescheduleTips: {
    title: 'Assembly service reschedule reminder',
    content: 'If you have ordered a separate assembly service, please contact customer service to change the appointment time for the assembly service',
  },
  scanGo: {
    title: 'Please book assembly service after placing your order.',
    desc: 'If you need assembly service, you can make an appointment at counter 7-8 of the customer service area on the first floor.',
    stores: {
      '401': {
        desc: 'If you require an assembly service, you can go to the Return and Exchange counter on the 2nd floor to book an appointment.'
      },
    }
  },
  offline: {
    relatedIkeaOrderNoLabel: 'Isell Order ID:',
    relatedIkeaOrderNoLabelCopied: '已复制订单号',
    relatedIkeaOrderNoLabelCopyFailed: '复制订单号失败，请重试',
    statusLabel: '服务状态',
  }
}
