import { DeliveryOption_Enums, OfflineDeliveryType_Enums } from '@/enums/delivery'
import { OrderChannel_Enums } from '@/enums/order'
import { compact, values } from 'lodash'

export const refactOfflineOrderDetail = (orderDetail) => {
  // 线下订单, 对deliveryInfos结构进行聚合处理逻辑
  // 服务端下发的数据
  // 1. delivery.offlineDeliveryType === 'CARRY' 最多存在一项 且排序在最前
  // 2. delivery.offlineDeliveryType === 'DELIVERY' 可以存在多项目
  // 3. delivery.offlineDeliveryType === 'DELIVERY' 且 ikeaOrderNO 具有重复的可能性, 在前端需要进行聚合
  if (orderDetail.data.orderChannel === OrderChannel_Enums.OFFLINE) {
    const {
      deliveryInfos
    } = orderDetail?.data ?? {}

    const _deliveryInfos = {
      carry: null,
      delivery: {},
      output: null,
    }

    deliveryInfos.forEach((delivery) => {
      // #CNODI-2723
      if (delivery.offlineDeliveryType === OfflineDeliveryType_Enums.FOOD) _deliveryInfos.food = delivery
      // 
      if (delivery.offlineDeliveryType === OfflineDeliveryType_Enums.CARRY) _deliveryInfos.carry = delivery
      if (delivery.offlineDeliveryType === OfflineDeliveryType_Enums.DELIVERY) {
        if (!_deliveryInfos.delivery[delivery.ikeaOrderNO]) {
          _deliveryInfos.delivery[delivery.ikeaOrderNO] = delivery
        } else {
          _deliveryInfos.delivery[delivery.ikeaOrderNO].deliveryItems =
            (_deliveryInfos.delivery[delivery.ikeaOrderNO].deliveryItems ?? [])
              .concat(delivery?.deliveryItems ?? [])
        }
      }
    })
    _deliveryInfos.output = compact([_deliveryInfos.food, _deliveryInfos.carry, ...values(_deliveryInfos.delivery)])

    orderDetail.data.__asserts__ = {
      ...(orderDetail.data.__asserts__ ?? {}),
      hasLogistics: !!Object.keys(_deliveryInfos.delivery).length,
    }

    orderDetail.data.deliveryInfos = _deliveryInfos.output
  } else if ([OrderChannel_Enums.ONLINE, OrderChannel_Enums.STORE_COMPANION].indexOf(orderDetail.data.orderChannel) > -1) {
    orderDetail.data.__asserts__ = {
      ...(orderDetail.data.__asserts__ ?? {}),
      hasLogistics: orderDetail.data.deliveryOption === DeliveryOption_Enums.HOME_DELIVERY
    }
  }

  if (orderDetail.data.deliveryOption === DeliveryOption_Enums.CLICK_COLLECT_STORE && orderDetail.data.pickupPointInfo) {
    const {
      deliveryInfos = []
    } = orderDetail.data

    const [
      pkg
    ] = deliveryInfos

    orderDetail.data.pickupPointInfo.package = pkg
  }

  return orderDetail
}

export default {
  refactOfflineOrderDetail
}
