export default {
  title: 'Refund Detail',
  refundResult: 'Refund Sucessfully',
  refundResultDesc: 'You can see your money back from your account',
  totalAmount: 'Refund Amount',
  time: 'Refund Time',
  route: 'Refund Account',
  orderNo: 'Order Number',
  errorMsg: 'Reload',
  multiRecordsNotice: 'Current order contains multiple refund records',
}
