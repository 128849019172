const goToPipInfo = function(options) {
  try {
    const url = `/pages/_pip/_pip?itemNo=${options.itemNo}&itemType=${options.itemType}&prePageType=order_detail&orderNo=${options.orderNo}`
    if (window.wx) {
      window.wx.miniProgram.navigateTo({ url });
    } else {
      console.error("scan-go go to payment without window.wx:", options)
    }
  } catch (error) {
    console.error("scan-go go to payment error:", error, options)
  }
}
export default goToPipInfo
