import Button from './Button/index.vue'
import Card from './Card/index.vue'
import FlexCell from './FlexCell/index.vue'
import Icon from './Icon/index.vue'
import Notice from './Notice/index.vue'
import Price from './Price/index.vue'
import SpaceBetweenCell from './SpaceBetween/cell.vue'
import SpaceBetween from './SpaceBetween/index.vue'

import ItemsTube from './ItemsTube/index.vue'
import Layout from './Layout/index.vue'
import OrderDetailLayout from './Layout/OrderDetail.vue'
import Navigation from './Navigation/index.vue'
// import OrderCard from './OrderCard/index.vue'
import OrderNo from './OrderNo/index.vue'
import OrderStatus from './OrderStatus/index.vue'
import PackageCard from './PackageCard/index.vue'
import ProdCard from './ProdCard/index.vue'
import ProdImages from './ProdImages/index.vue'
import ServiceCard from './ServiceCard/index.vue'
import Steps from './Steps/index.vue'

import AlertMessage from './AlertMessage/index.vue'
import Tag from './Tag/index.vue'

import Cell from './Cell/index.vue'

export const IkButton = Button
export const IkPrice = Price
export const IkIcon = Icon
export const IkCard = Card

export const IkFlexCell = FlexCell
export const IkSpaceBetween = SpaceBetween
export const IkSpaceBetweenCell = SpaceBetweenCell
export const IkNotice = Notice

export const IkLayout = Layout
export const IkNavigation = Navigation
export const IkProdCard = ProdCard
export const IkProdImages = ProdImages
// export const IkOrderCard = OrderCard
export const IkOrderDetailLayout = OrderDetailLayout
export const IkOrderNo = OrderNo
export const IkOrderStatus = OrderStatus
export const IkServiceCard = ServiceCard
export const IkSteps = Steps
export const IkPackageCard = PackageCard
export const IkItemsTube = ItemsTube

export const IkAlertMessage = AlertMessage
export const IkTag = Tag
export const IKCell = Cell
