import order from './order';
import orderStatus from './orderStatus';

export default {
  title: 'My Orders',
  sources: {
    all: {
      text: 'All orders'
    },
    online: {
      text: 'Online Orders',
    },
    offline: {
      text: 'Store Orders'
    },
    ent: {
      text: 'Business Network management centre'
    },
  },
  cates: {
    all: 'All',
    ...orderStatus,
    after_sales: 'After Sales',
  },
  order: {
    ...order
  },
  no_orders_yet: 'You have no active or previous orders',
  no_orders_yet_offline: 'Showing store orders since Nov 2021',
  reach_bottom: 'Nothing More',
  reach_bottom_offline: 'Showing store orders since Nov 2021',
  reload: 'Reload',
  service_down: 'Server exception, please try again later',
  order_status_popover: 'CLick to show store orders',
  breadCrumb: {
    items: [
      {
        "url": "/profile/myprofile",
        "text": "My profile"
      },
      {
        "text": "My orders"
      },
    ]
  },
  invoice_tip: {
    title: 'Reminder',
    desc: 'We are sorry that the invoice cannot be issued because the shipment is still in process. Please try to issue the invoice after the delivery is completed. Thank you for your understanding and support.',
    confirmText: 'Got it',
    contact: 'Please contact the store',
  },
  order_channel_switch: 'More'
}
