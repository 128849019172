
import { DEFAULT_PAGINATION } from '@/enums'
import { PageStatus_Enums } from '@/enums/order'
import { ordersService } from '@/service'

const getDefaultState = () => {
  return {
    status: PageStatus_Enums.EMPTY,
    pagination: { ...DEFAULT_PAGINATION },
    orderList: [],
    requestError: false,
    refundData: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    
  },
  actions: {
    /**
     * @description 获取待晒单列表详情
     * @param pageIndex 分页页数
     * @param pageSize 分页每页size
     */
    async fetchPostViewListData({ state, commit }, payload) {
      const { isNextPage = false, isRefresh = false } = payload || {}
      if (!isNextPage) {
        state.pagination = { ...DEFAULT_PAGINATION }
      }
      if (state.pagination.isFinished) {
        return
      }
      if (!isRefresh && !isNextPage) {
        state.orderList = []
        state.requestError = false
      }
      
      if (isRefresh) {
        state.pagination.isRefreshing = isRefresh || false
      } else {
        state.pagination.isLoading = true
      }

      const params = {
        pageIndex: state.pagination.pageIndex,
        pageSize: state.pagination.pageSize
      }
      ordersService.getPostViewList(params).then(res => {
        const { data: { data = [], total = 0, } } = res
        if (isNextPage) {
          state.orderList = state.orderList.concat(data)
        } else {
          state.orderList = data
        }
        state.pagination.isFinished = (state.pagination.pageIndex * state.pagination.pageSize) >= total
        state.pagination.pageIndex += 1
        state.requestError = false
        state.pagination.isLoading = false
      }).catch(err => {
        if (err?.message === 'request cancel') {
          state.requestError = false
        } else {
          state.requestError = true
          state.pagination.isLoading = false
        }
        console.log(err)
      }).finally(() => {
        state.pagination.isLoading = false
        state.pagination.isRefreshing = false
      })
    }
  }
}
