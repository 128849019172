import bridge from '@/bridge';
import md5 from 'md5';
export default class Tracker {
  private platform_var: string;
  readonly pageType_var: string;
  readonly pageName_var: string;
  private ut_var: string | number[];

  constructor(payload) {
    bridge.tracking.init({ useNative: true })
    this.platform_var = ''
    this.ut_var = ''
    this.pageType_var = payload.pageType_var
    this.pageName_var = payload.pageName_var
    this.setPlatformVar()
    this.setToken()
  }
  private setToken = async () => {
    const token = await bridge.getToken()
    this.ut_var = md5(token.value)
  }
  private setPlatformVar = async () => {
    const result = await bridge.currentPlatform()
    const platform = result.value?.platform.toString().toUpperCase() ?? ''
    if (platform?.includes('IOS')) {
      this.platform_var = 'iOS'
    } else if (platform?.includes('ANDROID')) {
      this.platform_var = 'Android'
    } else if (platform.includes('WECHATMINIPROGRAM') || platform.includes('STORECOMPANION')) {
      this.platform_var = 'WechatMiniprogram'
    } else if (platform?.includes('PCWEB')) {
      this.platform_var = 'pc'
    } else {
      this.platform_var = 'h5'
    }
  }

  public sendTrack = async (name, params, eventKey = '') => {
    if (params?.message_var) {
      window.parent.postMessage({
        name: 'MESSAGE_FROM_ORDER',
        type: 'track',
        value: params.message_var
      }, '*')
    }
    try {
      console.log('this.platform_var', this.platform_var)
      bridge.tracking.track(name, eventKey, {
        eventName: name,
        platform_var: this.platform_var,
        pageType_var: this.pageType_var,
        pageName_var: this.pageName_var,
        ut_var: this.ut_var,
        timestamp_var: new Date().valueOf(),
        ...params,
      })
    } catch (err) {
      console.log('trackerErr')
    }
  }
}
