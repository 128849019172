<template>
  <div class="cell">
    <div class="left">
      <div class="icon" v-if="leftIconSlot">
        <slot name="icon"></slot>
      </div>
      <div class="content">
        <div class="title" v-if="titleSlot"><slot name="title"></slot></div>
        <div class="desc" v-if="descSlot"><slot name="desc"></slot></div>
      </div>
    </div>
    <div class="right" v-if="rightIconSlot">
      <slot name="right-icon"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, useSlots } from 'vue'

export default defineComponent({
  props: {
    
  },
  components: {
  },
  setup() {
    const leftIconSlot = !!useSlots().icon
    const titleSlot = !!useSlots().title
    const descSlot = !!useSlots().desc
    const rightIconSlot = !!useSlots()['right-icon']
    return {
      leftIconSlot,
      titleSlot,
      descSlot,
      rightIconSlot
    }
  },
})
</script>

<style lang="less" scoped>
.cell {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .left {
    display: inline-flex;
    justify-content: center;
    .icon {
      display: inline-flex;
      justify-content: center;
      line-height: 24px;
    }
    .content {
      line-height: 24px;
      .title {
        font-size: 14px;
        color: #111;
        line-height: 24px;
        font-weight: 700;
      }
      .desc {
        font-size: 12px;
        color: #666;
        line-height: 24px;
      }
    }
  }
}
</style>
