<template>
  <div :class="`image-wrapper ${imageStatus}`">
    <img
      class="image"
      :src="imageSrc"
      @error="handleImageErrorFallback"
      v-if="imageSrc"
    />
    <img
      class="image-big"
      :src="errorImageSrc"
      v-else-if="errorImageSrc"
    />
    <img
      class="image"
      :src="defaultImageSrc"
      v-else
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    src: {
      type: String,
      default: ''
    },
    class: {
      type: String,
      default: ''
    },
    errorImageSrc: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const imageSrc = ref(props.src)
    const imageClass = ref(props.class)
    const imageStatus = ref(`image-${props.src ? '200' : '0'}`)
    const defaultImageSrc = ref('https://res.app.ikea.cn/content/u/20230324/766b12ec8c9849f78c53c7ed47179920.png')

    const handleImageErrorFallback = () => {
      imageSrc.value = 'https://res.app.ikea.cn/content/u/20230324/932ca4db18884ee98f06c8abacd6d0f1.png'
      imageStatus.value = 'image-404'
    }

    return {
      defaultImageSrc,
      imageClass,
      imageSrc,
      imageStatus,
      handleImageErrorFallback,
    }
  }
})
</script>

<style lang="less" scoped>
@import '../../../ikea.order.less';
.image {
  // width: 100%;
  height: 100%;
  max-width: 72px;
  max-height: 72px;
  border-radius: inherit;

  &-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    // border: 1px solid #000;
    position: relative;
    .image-big {
      border-radius: 0;
      height: 100%;
      width: 100%;
    }
  }
  &-404, &-0 {
    background: #F5F5F5;
    background-size: 10%;
  }
  &-404 {
    img {
      border-radius: 0;
      .s750(width, 24px);
      .s750(height, 24px);
    }
  }
  &-0 {
    img {
      border-radius: 0;
      .s750(width, 24px);
      .s750(height, 22px);
    }
  }
}
</style>
