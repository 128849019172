<template>
  <ik-space-between rightGrow>
    <template #left>
      <div class="label" v-if="slots.label"><slot name="label"></slot></div>
    </template>
    <template #right>
      <div class="value">
        <div ref="wrapperRef" class="items-wrapper"  @click.prevent.stop="handleClick(item)">
          <div ref="itemsRef" class="items">
            <span class="item" v-for="it in item.childItems" :key="it.itemNO">
              <Image :src="it.itemPicture" />
            </span>
          </div>
        </div>
        <div class="action">
          <div class="suffix" v-if="slots.suffix"><slot name="suffix"></slot></div>
          <div class="etc" v-if="itemsWidth - wrapperWidth > 0"></div>
          <div class="arrow"></div>
        </div>
      </div>
    </template>
  </ik-space-between>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import SpaceBetween from '../SpaceBetween/'
import Image from '../Image'

export default defineComponent({
  components: {
    IkSpaceBetween: SpaceBetween,
    Image,
  },
  props: {
    item: Object,
    onClick: Function,
  },
  setup(props, context) {
    props = reactive(props);
    const slots = context.slots;

    const wrapperRef = ref(null)
    const itemsRef = ref(null)

    const wrapperWidth = ref(0)
    const itemsWidth = ref(0)

    onMounted(() => {
      wrapperWidth.value = wrapperRef.value.clientWidth
      itemsWidth.value = itemsRef.value.clientWidth
    })

    const handleClick = (item) => {
      props.onClick && props.onClick(item)
    }

    return {
      slots,
      wrapperRef,
      itemsRef,
      wrapperWidth,
      itemsWidth,
      handleClick,
    }
  },
})
</script>

<style lang="less" scoped>
.label {
  flex: 0 0 auto;
  font-size: 12px;
  color: #666;
  margin-right: 20px;
  width: 72px;
  text-align: right;
  // white-space: nowrap;
}

.value {
  flex: 1 1 auto;
  // margin-left: 1rem;
  // width: calc(100vw - 7rem);
  // width: 100%;
  padding: 4px;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 48px;
  cursor: pointer;
  // width: calc(100% - 8rem);
  .items-wrapper {
    display: flex;
    flex: 1 1 auto;
    width: 1rem;
    overflow: scroll;
    .items {
      display: flex;
      .item {
        width: 28px;
        display: inline-block;
        height: 27px;
        margin: 4px;
        // overflow: hidden;
        // background-image: url('../../../assets/icons/icon_assembly_service.svg');
        // background-repeat: no-repeat;
        // background-size: cover;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .action {
    flex: 0 0 auto;
    align-items: center;
    // width: 3rem;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    padding: 4px;
    .etc {
      background-image: url('../../../assets/icons/icon_etc.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 14px;
      height: 16px;
      margin: 0 8px;
    }
    .arrow {
      background-image: url('../../../assets/icons/dark/icon_forward.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: .5rem;
      height: .75rem;
      // margin: 0 8px;
    }
    //
    .suffix {
      transform: translate(-2px, -1px);
    }
  }
}
</style>
