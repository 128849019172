export default {
  notes: {
    processing: '订单正在进行售后'
  },
  actions: {
    viewProgress: {
      text: '售后进度'
    }
  },
  statusPage: {
    title: '售后状态',
    actions: {
      iknow: {
        text: '我知道了'
      }
    },
    status_enums: {
      SELF_SERVICE_RETURN: {
        CREATED: {
          title: '待处理',
          desc: '您的退货工单已受理，我们将在48小时内处理您的问题，请您耐心等待。',
        },
        PROCESSING: {
          title: '处理中',
          desc: '您的退货工单已有专员处理，我们会在48小时内通过短信回复处理结果，建议您及时关注短信收件箱信息。',
        },
        COMPLETED: {
          title: '已完结',
          desc: '您提交的售后需求已处理，具体处理方案以宜家客服与您的沟通结果为准，感谢您选择宜家',
        },
      },
      SELF_SERVICE_EXCHANGE: {
        CREATED: {
          title: '待处理',
          desc: '您的换货工单已受理，我们将在48小时内处理您的问题，请您耐心等待。',
        },
        PROCESSING: {
          title: '处理中',
          desc: '您的换货工单已有专员处理，我们会在48小时内通过短信回复处理结果，建议您及时关注短信收件箱信息。',
        },
        COMPLETED: {
          title: '已完结',
          desc: '您提交的售后需求已处理，具体处理方案以宜家客服与您的沟通结果为准，感谢您选择宜家',
        },
      }
    },
  },
  wxLiveStream: {
    headline: '前往视频号订单页申请售后',
    details: '请前往 [微信 -> 视频号 -> 个人中心 -> 订单列表页] 进行售后处理。',
    details1: '订单状态更新存在延迟，请稍作等待',
  }
}
