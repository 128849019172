import { ordersService } from "@/service"

const getDefaultState = () => {
  return {
    data: null,
    epidemicsDsecShow: false, // 疫情描述展示
    noticeTipShow: false, // 通知提示展示
    recommendationPagination: { // 推荐商品的数据
      goodsData: [],
      moreToken: '',
      waterfallLoading: false,
      isEnd: false
    }
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateOrderDetail(state, newState) {
      state = newState
    },
    updateNoticeTipShow(state, payload) {
      state.noticeTipShow = payload
    },
    updateRecommendationPagination(state, payload) {
      state.recommendationPagination = payload
    }
  },
  actions: {
    async getRecommendGoods({ state, commit }, payload) {
      if (state.recommendationPagination?.waterfallLoading) return
      state.recommendationPagination.waterfallLoading = true
      try {
        const res = await ordersService.getRecommendationData({
          params: payload,
          moreToken: state.recommendationPagination.moreToken
        })
        commit('updateRecommendationPagination', {
          goodsData: state.recommendationPagination.goodsData.concat(...res.items),
          moreToken: res.moreToken,
          waterfallLoading: false,
          isEnd: !res.moreToken
        })
        state.recommendationPagination.waterfallLoading = false
      } catch (e) {
        state.recommendationPagination.waterfallLoading = false
      }
    }
  }
}
