const getDefaultState = () => {
  return {
    data: null,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateCheckReview(state, newState) {
      state = newState
    },
    resetCheckReview(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {

  }
}
