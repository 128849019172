import orderActions from './orderActions'
export default {
  title: 'More Products',
  end: 'No More Products',
  addProductsToShoppingCart: {
    not_support_online_sales: 'Online purchase is not supported',
    warning_tips: 'Try Again',
    out_of_stock: 'Out of Stock',
    actions: {
      ...orderActions,
      goToCart: 'View >',
    },
    status: {
      success: 'Added successfully',
      successWithCount: '{count} products added into the shopping bag',
      failed: 'Sorry, failed to add to shopping bag, please try again later.'
    }
  }
}
