<template>
  <template v-if="from && to">
    <!-- 非同一天 -->
    <strong v-if="moment(from).startOf('day').valueOf() !== moment(to).startOf('day').valueOf()">
      {{moment(from).format(lang.message.system.date.mmdd)}}-{{moment(to).format(lang.message.system.date.mmdd)}}
    </strong>
    <!-- 同一天不同时间 -->
    <strong v-if="from !== to && moment(from).startOf('day').valueOf() === moment(to).startOf('day').valueOf()">
      {{moment(to).format(`dddd ${lang.message.system.date.mmdd}`)}} {{moment(from).format('HH:mm')}}-{{moment(to).format('HH:mm')}}
    </strong>
    <!-- 同时间 -->
    <strong v-if="(from === to)">
      {{moment(to).format(`dddd ${lang.message.system.date.mmdd} HH:mm`)}}
    </strong>
  </template>

  <strong v-if="(!from && to)">
    {{moment(to).format(`dddd ${lang.message.system.date.mmdd} HH:mm`)}}
  </strong>
</template>

<script>
import { defineComponent } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import { lang } from '../../../locales'

export default defineComponent({
  props: ['from', 'to'],
  setup(props, context) {
    const store = useStore()
    const slots = context.slots

    return {
      slots,
      moment,
      lang,
    }
  },
})
</script>
