const store = {
  '058': {
    name: 'IKEA Tianjin',
    addr: 'No.433,Jintang Road Dongli District',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=4a83c06f9e4211eab6c81b27a8574253',
    cmsId: '4a83c06f9e4211eab6c81b27a8574253'
  },
  '164': {
    name: 'IKEA Wuxi',
    addr: 'No. 1, Tuanjie Middle Rd.,Xishan',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=1c5115a1a16b11eab3f08dec0649fce5',
    cmsId: '1c5115a1a16b11eab3f08dec0649fce5'
  },
  '214': {
    name: 'IKEA Beijing - Xihongmen',
    addr: 'Build 2 No.15 Xinning street,Daxing',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=dcc575b5a1a711ea88dd71c4bc57528c',
    cmsId: 'dcc575b5a1a711ea88dd71c4bc57528c'
  },
  '247': {
    name: 'IKEA Shanghai - Baoshan',
    addr: 'No.1818, Hutai Rd.,Baoshan District',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=8497a449a3b511eaa5056f153dce146c',
    cmsId: '8497a449a3b511eaa5056f153dce146c'
  },
  '279': {
    name: 'IKEA Ningbo',
    addr: 'No. 525, Yiyuan Rd, Yinzhou Dis.',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=e99b49e9a54b11eaa5056f153dce146c',
    cmsId: 'e99b49e9a54b11eaa5056f153dce146c'
  },
  '330': {
    name: 'IKEA Chongqing',
    addr: 'No.2 Huiliu Rd,North New District',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=f3ce563c9fbe11eab3f08dec0649fce5',
    cmsId: 'f3ce563c9fbe11eab3f08dec0649fce5'
  },
  '401': {
    name: 'IKEA Hangzhou',
    addr: 'No.5 West Qiaomo Rd, Yuhang D',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=010af166a18811ea88dd71c4bc57528c',
    cmsId: '010af166a18811ea88dd71c4bc57528c'
  },
  '424': {
    name: 'IKEA Xi\'an',
    addr: 'South East of Sanqiao Viaduct',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=653e2cc0a3d011eaa5056f153dce146c',
    cmsId: '653e2cc0a3d011eaa5056f153dce146c'
  },
  '459': {
    name: 'IKEA Guangzhou - Foshan',
    addr: 'No.18 North Guilan Rd, Guicheng St',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=9603a67da70811ea935571269bb24132',
    cmsId: '9603a67da70811ea935571269bb24132'
  },
  '571': {
    name: 'IKEA Guiyang - Guanshanhu',
    addr: 'No2 Jinqing Avenue,Guanshanhu D',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=44c89e42a84c11e9863f07c37ec6982d',
    cmsId: '44c89e42a84c11e9863f07c37ec6982d'
  },
  '485': {
    name: 'IKEA Harbin',
    addr: 'NO.48, Fudan Street, Nangang Dis',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=8c8d2535a3e511eaa5056f153dce146c',
    cmsId: '8c8d2535a3e511eaa5056f153dce146c'
  },
  '558': {
    name: 'IKEA Tianjin - Zhongbei',
    addr: 'No.7 Wanhui Road, Zhongbei Town',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=71debdf49fc811eab3f08dec0649fce5',
    cmsId: '71debdf49fc811eab3f08dec0649fce5',
  },
  '521': {
    name: 'IKEA Jinan',
    addr: 'No.121, Yantai Road, Huaiyin Dist.',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=624b30a0a60a11eaa5056f153dce146c',
    cmsId: '624b30a0a60a11eaa5056f153dce146c'
  },
  '495': {
    name: 'IKEA Dalian - Xianglujiao',
    addr: 'No.51, HAIDA NORTH ST., XIGANG DIST',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=7ebd24b9a3e311eaa5056f153dce146c',
    cmsId: '7ebd24b9a3e311eaa5056f153dce146c'
  },
  '544': {
    name: 'IKEA Guangzhou - Panyu',
    addr: 'No.139-15 Dongyi Rd, Panyu District',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=717f5119a63811eaa5056f153dce146c',
    cmsId: '717f5119a63811eaa5056f153dce146c'
  },
  '584': {
    name: 'IKEA Guangzhou - Tianhe',
    addr: 'No.663,East Huangpu Avenue',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=7d4f2a5885d111e983562d7c0d6a8721',
    cmsId: '7d4f2a5885d111e983562d7c0d6a8721'
  },
  '466': {
    name: 'IKEA Chengdu',
    addr: 'No.9, Zhanhua Road, Hi-Tech Zone',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=62231f29a22511eaa5056f153dce146c',
    cmsId: '62231f29a22511eaa5056f153dce146c'
  },
  '481': {
    name: 'IKEA Nanjing - Qinhuai',
    addr: 'No.99 Ming Shi road',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=5b90aaf19e3e11eab6c81b27a8574253',
    cmsId: '5b90aaf19e3e11eab6c81b27a8574253'
  },
  '418': {
    name: 'IKEA Chengdu - Chenghua',
    addr: 'No. 9 Jiangjunbei Rd, Rongdu Avenue',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=d9943569a3ad11eaa5056f153dce146c',
    cmsId: 'd9943569a3ad11eaa5056f153dce146c'
  },
  '484': {
    name: 'IKEA Suzhou store',
    addr: 'No.17 Chengji Road, Xushuguan Town',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=be2ac450a3ea11eaa5056f153dce146c',
    cmsId: 'be2ac450a3ea11eaa5056f153dce146c'
  },
  '512': {
    name: 'IKEA Nantong',
    addr: 'No.66, Huanghai Road, Gangzha Dis',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=a33828f0a6f011ea93a0019f9b8418aa',
    cmsId: 'a33828f0a6f011ea93a0019f9b8418aa'
  },
  '340': {
    name: 'IKEA Wuhan - Qiaokou',
    addr: 'No.2 Zhangbihu Rd, Qiao kou Dis.',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=ebcf5569a0c011eab3f08dec0649fce5',
    cmsId: 'ebcf5569a0c011eab3f08dec0649fce5'
  },
  '568': {
    name: 'IKEA Xuzhou - Jing Kai',
    addr: 'No.128, He Ping Rd',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=091a7f0ba3de11eaa5056f153dce146c',
    cmsId: '091a7f0ba3de11eaa5056f153dce146c'
  },
  '572': {
    name: 'IKEA Zhengzhou - Huiji',
    addr: 'No.2 Changxing Rd, Huiji District',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=cfdb19afa3a711eaa5056f153dce146c',
    cmsId: 'cfdb19afa3a711eaa5056f153dce146c'
  },
  '581': {
    name: 'IKEA Changsha - Yuelu',
    addr: 'No 253 Yanghu road, Yueli',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=38cda8e69fe911eab3f08dec0649fce5',
    cmsId: '38cda8e69fe911eab3f08dec0649fce5'
  },
  '833': {
    name: 'IKEA Shenzhen - Nanshan',
    addr: 'No. 8188 Beihuan Rd. Nanshan Dist.',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=92b77634835011e983562d7c0d6a8721',
    cmsId: '92b77634835011e983562d7c0d6a8721'
  },
  '585': {
    name: 'IKEA Shanghai - Yangpu',
    addr: 'Room101,No.135 Guowei Road',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=3284b1aaa3d511eaa5056f153dce146c',
    cmsId: '3284b1aaa3d511eaa5056f153dce146c'
  },
  '601': {
    name: 'IKEA Qingdao',
    addr: 'No.100 Shenzhen Road',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=fae80220937f11eab002599e2b3fb8c1',
    cmsId: 'fae80220937f11eab002599e2b3fb8c1'
  },
  '621': {
    name: 'IKEA Kunming',
    addr: 'NO.168 Wangqiong Road, Wuhua Dist.',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=0f9c9470eea011eb909ec36c6e99b004',
    cmsId: '0f9c9470eea011eb909ec36c6e99b004'
  },
  '624': {
    name: 'IKEA Fuzhou',
    addr: 'No.789 Fuma Road Jin an District',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=25351730f32411eab15e43097300dec7',
    cmsId: '25351730f32411eab15e43097300dec7'
  },
  '630': {
    name: 'IKEA Nanning',
    addr: 'No. 12 Pingle Avenue, Liangqing District',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=4d67e040fc1811eb909ec36c6e99b004',
    cmsId: '4d67e040fc1811eb909ec36c6e99b004'
  },
  '644': {
    name: 'IKEA Shanghai - Jingan',
    addr: 'No. 1728 West Nanjing Rd.',
    type: 'EXPERIENCE_CENTER',
    url: 'https://ikeaapp/cms/page?id=599c7490bd1511ea928217435a65a858',
    cmsId: '599c7490bd1511ea928217435a65a858'
  },
  '802': {
    name: 'IKEA Beijing - Siyuanqiao',
    addr: 'No.59, the futong eastern street',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=375a0b02a18d11ea88dd71c4bc57528c',
    cmsId: '375a0b02a18d11ea88dd71c4bc57528c'
  },
  '856': {
    name: 'IKEA Shanghai - Xuhui',
    addr: 'No.126 Cao Xi Road',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=8d205890a3ca11eaa5056f153dce146c',
    cmsId: '8d205890a3ca11eaa5056f153dce146c'
  },
  '885': {
    name: 'IKEA Shanghai - Beicai',
    addr: 'No.550, Linyu Road',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=3e6e4980b7f011e9863f07c37ec6982d',
    cmsId: '3e6e4980b7f011e9863f07c37ec6982d'
  },
  '886': {
    name: 'IKEA Shengyang - Tie Xi',
    addr: 'No. 20, North Xinghua Street,Tie Xi',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=51616d72a3e111eaa5056f153dce146c',
    cmsId: '51616d72a3e111eaa5056f153dce146c'
  },
  '21401': {
    name: 'IKEA Beijing -  Wukesong Experience Centre',
    addr: 'No.69 Court, Fuxing Road, Haidian District, Beijing',
    type: 'EXPERIENCE_CENTER',
    url: 'https://ikeaapp/cms/page?id=67f936bdc32611e9a11337c41edc6b54',
    cmsId: '67f936bdc32611e9a11337c41edc6b54'
  },
  '27901': {
    name: 'IKEA Wenzhou Experience Centre',
    addr: '1st Floor MIXC, No.99, Wenrui Avenue, Ouhai District, Wenzhou, Zhejiang',
    type: 'EXPERIENCE_CENTER',
    url: 'https://ikeaapp/cms/page?id=2bd3b578a48211eaa5056f153dce146c',
    cmsId: '2bd3b578a48211eaa5056f153dce146c'
  },
  '957': {
    name: 'IKEA Store of Tomorrow',
    addr: 'No.126 Cao Xi Road',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=8d205890a3ca11eaa5056f153dce146c',
    cmsId: '8d205890a3ca11eaa5056f153dce146c'
  },
}

export default store

// 058    天津东丽商场    IKEA Tianjin    STANDARD    https://ikeaapp/cms/page?id=4a83c06f9e4211eab6c81b27a8574253
// 164    无锡商场    IKEA Wuxi    STANDARD    https://ikeaapp/cms/page?id=1c5115a1a16b11eab3f08dec0649fce5
// 214    北京西红门商场    IKEA Beijing - Xihongmen    STANDARD    https://ikeaapp/cms/page?id=dcc575b5a1a711ea88dd71c4bc57528c
// 247    上海宝山商场    IKEA Shanghai - Baoshan    STANDARD    https://ikeaapp/cms/page?id=8497a449a3b511eaa5056f153dce146c
// 279    宁波商场    IKEA Ningbo    STANDARD    https://ikeaapp/cms/page?id=e99b49e9a54b11eaa5056f153dce146c
// 330    重庆商场    IKEA Chongqing    STANDARD    https://ikeaapp/cms/page?id=f3ce563c9fbe11eab3f08dec0649fce5
// 401    杭州商场    IKEA Hangzhou    STANDARD    https://ikeaapp/cms/page?id=010af166a18811ea88dd71c4bc57528c
// 424    西安商场    IKEA Xi'an    STANDARD    https://ikeaapp/cms/page?id=653e2cc0a3d011eaa5056f153dce146c
// 459    佛山商场    IKEA Guangzhou - Foshan    STANDARD    https://ikeaapp/cms/page?id=9603a67da70811ea935571269bb24132
// 571    贵阳商场    IKEA Guiyang - Guanshanhu    STANDARD    https://ikeaapp/cms/page?id=44c89e42a84c11e9863f07c37ec6982d
// 485    哈尔滨商场    IKEA Harbin    STANDARD    https://ikeaapp/cms/page?id=8c8d2535a3e511eaa5056f153dce146c
// 558    天津中北商场    IKEA Tianjin - Zhongbei    STANDARD    https://ikeaapp/cms/page?id=71debdf49fc811eab3f08dec0649fce5
// 521    济南商场    IKEA Jinan    STANDARD    https://ikeaapp/cms/page?id=624b30a0a60a11eaa5056f153dce146c
// 495    大连商场    IKEA Dalian - Xianglujiao    STANDARD    https://ikeaapp/cms/page?id=7ebd24b9a3e311eaa5056f153dce146c
// 544    广州番禺商场    IKEA Guangzhou - Panyu    STANDARD    https://ikeaapp/cms/page?id=717f5119a63811eaa5056f153dce146c
// 584    广州天河商场    IKEA Guangzhou - Tianhe    STANDARD    https://ikeaapp/cms/page?id=7d4f2a5885d111e983562d7c0d6a8721
// 466    成都高新商场    IKEA Chengdu    STANDARD    https://ikeaapp/cms/page?id=62231f29a22511eaa5056f153dce146c
// 481    南京商场    IKEA Nanjing - Qinhuai    STANDARD    https://ikeaapp/cms/page?id=5b90aaf19e3e11eab6c81b27a8574253
// 418    成都成华商场    IKEA Chengdu - Chenghua    STANDARD    https://ikeaapp/cms/page?id=d9943569a3ad11eaa5056f153dce146c
// 484    苏州商场    IKEA Suzhou store    STANDARD    https://ikeaapp/cms/page?id=be2ac450a3ea11eaa5056f153dce146c
// 512    南通商场    IKEA Nantong    STANDARD    https://ikeaapp/cms/page?id=a33828f0a6f011ea93a0019f9b8418aa
// 340    武汉商场    IKEA Wuhan - Qiaokou    STANDARD    https://ikeaapp/cms/page?id=ebcf5569a0c011eab3f08dec0649fce5
// 568    徐州商场    IKEA Xuzhou - Jing Kai    STANDARD    https://ikeaapp/cms/page?id=091a7f0ba3de11eaa5056f153dce146c
// 572    郑州商场    IKEA Zhengzhou - Huiji    STANDARD    https://ikeaapp/cms/page?id=cfdb19afa3a711eaa5056f153dce146c
// 581    长沙商场    IKEA Changsha - Yuelu    STANDARD    https://ikeaapp/cms/page?id=38cda8e69fe911eab3f08dec0649fce5
// 833    深圳商场    IKEA Shenzhen - Nanshan    STANDARD    https://ikeaapp/cms/page?id=92b77634835011e983562d7c0d6a8721
// 585    上海杨浦商场    IKEA Shanghai - Yangpu    STANDARD    https://ikeaapp/cms/page?id=3284b1aaa3d511eaa5056f153dce146c
// 601    青岛商场    IKEA Qingdao    STANDARD    https://ikeaapp/cms/page?id=fae80220937f11eab002599e2b3fb8c1
// 624    福州商场    IKEA Fuzhou    STANDARD    https://ikeaapp/cms/page?id=25351730f32411eab15e43097300dec7
// 644    上海静安城市店    IKEA Shanghai - Jingan    EXPERIENCE_CENTER    https://ikeaapp/cms/page?id=599c7490bd1511ea928217435a65a858
// 802    北京四元桥商场    IKEA Beijing - Siyuanqiao    STANDARD    https://ikeaapp/cms/page?id=375a0b02a18d11ea88dd71c4bc57528c
// 856    上海徐汇商场    IKEA Shanghai - Xuhui (Upgrading)    STANDARD    https://ikeaapp/cms/page?id=8d205890a3ca11eaa5056f153dce146c
// 885    上海北蔡商场    IKEA Shanghai - Beicai    STANDARD    https://ikeaapp/cms/page?id=3e6e4980b7f011e9863f07c37ec6982d
// 886    沈阳商场    IKEA Shengyang - Tie Xi    STANDARD    https://ikeaapp/cms/page?id=51616d72a3e111eaa5056f153dce146c
// 21401    北京五棵松体验中心    IKEA Beijing -  Wukesong Experience Centre    EXPERIENCE_CENTER    https://ikeaapp/cms/page?id=67f936bdc32611e9a11337c41edc6b54
// 27901    温州体验中心    IKEA Wenzhou Experience Centre    EXPERIENCE_CENTER    https://ikeaapp/cms/page?id=2bd3b578a48211eaa5056f153dce146c
// 957    明日之店    IKEA Store of Tomorrow    STANDARD    https://ikeaapp/cms/page?id=8d205890a3ca11eaa5056f153dce146c
