<template>
  <div
    class="wrapper"
    :data-mode="mode"
  >
    <price
      :price="computedPrice"
      :size="size"
      :mode="mode"
      :style="style"
    >
      <template #polar="props">
        {{(polar || props.price.polar === '-') ? props.price.polar : null}}
      </template>
      <template #symbol="props">

        {{props.price.symbol}}
      </template>
      <template #ints="props">

        {{props.price.ints}}
      </template>
      <template #decimal="props">

        {{props.price.decimal}}
      </template>
      <template #cents="props">

        {{props.price.cents}}
      </template>
    </price>
  </div>
</template>

<script>
import { reactive, computed } from 'vue';
import Price from './price.vue'
import { currency } from './utils';

export default {
  name: 'ik-price',

  props: {
    size: {
      type: String,
      default: 'normal'
    },
    mode: {
      type: String,
      default: 'default'
    },
    style: {
      type: Object,
    },
    price: {
      type: Number,
      default: 0.00
    },
    polar: {
      type: Boolean,
      default: false,
    }
  },

  components: {
    Price,
  },

  setup(props, context) {
    props = reactive(props);

    const slots = context.slots;

    const computedPrice = computed(() => currency(props.price)) 

    return {
      slots,
      computedPrice,
    }
  },
};
</script>
