import http from '@/utils/http'
import axios from 'axios'

const getTrackAndTraceInfo = async(params) => {
  const needValidate = params.seccode && params.validate && params.challenge

  const requestOptions = {
    method: needValidate ? 'POST' : 'GET',
    api: needValidate ? 'FETCH_TRACK_AND_TRACE_INFO_FOR_YHT'
      : (/csc/.test(location.hostname) ? 'FETCH_TRACK_AND_TRACE_INFO_FOR_YHT_CSC' : 'FETCH_TRACK_AND_TRACE_INFO'),
  }

  const _params = {
    ikeaOrderNO: params.ikeaOrderNo,
    orderId: params.orderId,
    seccode: params.seccode,
    challenge: params.challenge,
    validate: params.validate,
  }

  if (/csc/.test(location.hostname)) {
    return axios.request({
      baseURL: import.meta.env.VITE_APP_ORIGIN_4_YHT_CSC,
      url: '/order-search/orders/trackTraceRcmp',
      params,
      method: 'GET',
    }).then((res) => {
      if (res?.data?.code !== '0') {
        return new Error(JSON.stringify(res?.data))
      }
      return res.data || {}
    }).catch(err => {
      return new Error(JSON.stringify(err))
    })
  } else {
    return (needValidate)
      ? http.post(requestOptions.api, _params)
        .then((res) => {
          return res.data || {}
        }).catch(async err => {
          // console.log('err::', err.response.data.code)
          if (err?.response?.data?.code === '06') {
            location.href = './index.html'
          } else {
            return new Error(JSON.stringify(err))
          }
        })
      : http.get(requestOptions.api, { params: _params })
        .then((res) => {
          return res.data || {}
        }).catch(async err => {
          return new Error(JSON.stringify(err))
        })
  }
}

function trackAndTraceService() {
  return {
    getTrackAndTraceInfo,
  }
}

export default trackAndTraceService()
