export enum DeliveryType_Enums {
  STANDARD = 'STANDARD',
  EXPRESS = 'EXPRESS',
  STANDARD_RD = 'STANDARD_RD',
  CURBSIDE = 'CURBSIDE',
}

export enum OfflineDeliveryType_Enums {
  CARRY = 'CARRY',
  DELIVERY = 'DELIVERY',
  FOOD = 'FOOD',
}

export enum TransportMethod_Enums {
  TRUCK = 'TRUCK',
  PARCEL = 'PARCEL',
}

export enum DeliveryOption_Enums {
  HOME_DELIVERY = 'HOME_DELIVERY',
  PUOP = 'PUOP', // 货柜自提/仓库自提
  COLLECT = 'COLLECT', // 所有自提的统称
  CLICK_COLLECT_STORE = 'CLICK_COLLECT_STORE', // 门店自提
  LCD = 'LCD', // a sales order is a type of order where customer purchases the goods from the store (selfserve, fullserve, satellite) and is shipped from a store.
}

export enum DeliveryStatus_Enums {
  INIT = 'INIT',
  PREPARING = 'PREPARING',
  AWAITING_COLLECTION = 'AWAITING_COLLECTION',
  COMPLETED = 'COMPLETED',
  DELIVERED = 'DELIVERED'
}
