export default {
  add_to_cart: 'Add To Bag',
  repurchase: 'Buy Again',
  cancel_order: 'Cancel Order',
  go_payment: 'Pay',
  post_review: 'Review',
  my_reviews: 'My Review',
  view_service: 'Service Status',
  view_logistics: 'Shipping Status',
  self_help_service: 'Self Service',
  apply_invoice: 'Issue Invoice',
  view_invoice: 'View Invoice',
  online_customer_service: 'Online service',
  electronic_receipt: 'View Receipt',
  go_to_refund_detail: 'Refund Detail',
  copy: {
    success: 'Order No. Copied',
    fail: 'Order No. Copy Failure',
  },
  invoice_switch_close: 'Dear customer, due to the surge in invoicing business during Double Eleven, the invoicing system has malfunctioned and is temporarily unable to issue invoices. We are doing our best to repair it and expect to restore it in about a week. Please scan the QR code to issue invoices later. We are very sorry for the inconvenience. Thank you for your understanding and support, IKEA wishes you a happy life!',
  invoice_card_pay: 'The current order is fully paid with the Ikea Wallet and cannot be invoiced.'
}
