<template>
  <div :class="`${classPrefix}-wrapper`" :style="style">
    <van-icon
      :class-prefix="classPrefix"
      :name="name"
      :style="`width: ${sizeProp}; height: ${sizeProp};`"
    />
    <div :class="`${classPrefix}--text`">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { Icon } from 'vant'

export default defineComponent({
  props: {
    name: {
      type: String,
    },
    size: {
      type: String,
      default: '16px'
    },
    style: {
      type: Object,
    }
  },
  components: {
    VanIcon: Icon
  },
  setup(props) {
    const classPrefix = 'ik-icon'
    const sizeProp = ref(props.size)

    watch(sizeProp, (value) => {
      sizeProp.value = value ?? '16px'
    })

    if (/^\d+$/.test(sizeProp.value)) {
      sizeProp.value = `${sizeProp.value}px`
    }

    return {
      classPrefix,
      sizeProp
    }
  },
})
</script>

<style lang="less" scoped>
.ik-icon-wrapper {
  display: flex;
  align-items: center;
}

.ik-icon--text {
  padding: 0 2px;
  font-size: 14px;
  color: #111;
  font-weight: 700;
}

.ik-icon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  &-search-order {
    background-image: url('../../../assets/icons/icon_search_order.svg');
  }
  &-back {
    background-image: url('../../../assets/icons/dark/icon_back.svg');
  }
  &-truck {
    transform: translateY(2px);
    background-image: url('../../../assets/icons/icon_truck.svg');
  }
  &-csc {
    background-image: url('../../../assets/icons/icon_csc.svg');
  }
  &-assembly {
    background-image: url('../../../assets/icons/icon_assembly.svg');
  }
  &-customer-service {
    background-image: url('../../../assets/icons/icon_customer_service.svg');
  }
  &-customer-service-4-store-companion {
    background-image: url('../../../assets/icons/icon_customer_service_4_store_companion.svg');
  }
  &-pure-customer-service {
    background-image: url('../../../assets/icons/icon_pure_customer_service.svg');
  }

  &-order-stat {
    &-waiting_for_payment {
      background-image: url('../../../assets/icons/icon_clock.svg');
    }
    &-completed {
      background-image: url('../../../assets/icons/icon_finished.svg');
    }
    &-paid {
      background-image: url('../../../assets/icons/icon_paid.svg');
    }
    &-delivered {
      background-image: url('../../../assets/icons/icon_delivered.svg');
    }
    &-post_review {
      background-image: url('../../../assets/icons/icon_completed.svg')
    }
    &-post_reviewed {
      background-image: url('../../../assets/icons/icon_finished.svg');
    }
    &-canceled {
      background-image: url('../../../assets/icons/icon_order_canceled.svg');
    }
  }
}
</style>
