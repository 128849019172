import { EOrderStatus, OrderChannel_Enums } from '@/enums/order'
import { assemblyService } from '@/service'
import * as Sentry from '@sentry/vue'
import lodash from 'lodash'

export interface KV {
  [key: string]: any
}

export interface IBookingDetail {
  status: string
  serviceReferenceItems: Array<KV> | null
  basePrice: number // 基础费用
  noneCalcPriceCnt?: number |null // 未计入计算的数量
  assemblyDifficultCnt?: number // 组装难度较大的数量
  cnServiceName?: string | null
  netAmount?: number | null
  serviceFromTime?: number | null
  serviceItemNo?: string | null
  serviceName?: string | null
  serviceTime?: number | null 
  taxAmount?: number |null
  isPatService?: boolean
}

export interface IAssemBlyCheckedInfo {
  checkedItemNOArr: Array<string>
  totalPrice: number
  withoutCount: number
  hasPatService: boolean
}

export interface IAssemblyServiceState {
  hasAssemblyService: boolean
  bookingDetail: IBookingDetail | null
  // assemBlyServiceModalShow: boolean
  pageLoading: boolean
  assemblyParts: KV | null
  assemblyCheckedInfo: IAssemBlyCheckedInfo
  deliveryContactInfo: KV | null
}

const getDefaultState = (): IAssemblyServiceState => {
  return {
    hasAssemblyService: false,
    bookingDetail: null,
    // assemBlyServiceModalShow: false,
    pageLoading: true,
    assemblyParts: null,
    assemblyCheckedInfo: {
      checkedItemNOArr: [],
      totalPrice: 0,
      withoutCount: 0,
      hasPatService: false
    },
    deliveryContactInfo: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateHasAssemblyService(state: IAssemblyServiceState, payload: boolean) {
      state.hasAssemblyService = payload
    },
    updateBookingDetail(state: IAssemblyServiceState, payload: IBookingDetail) {
      state.bookingDetail = payload
    },
    updateDeliveryContactInfo(state: IAssemblyServiceState, payload: KV | null) {
      state.deliveryContactInfo = payload
    },
    // updateAssemBlyServiceModalShow(state: IAssemblyServiceState, payload: boolean) {
    //   state.assemBlyServiceModalShow = payload
    // },
    updatePageLoading(state: IAssemblyServiceState, payload: boolean) {
      state.pageLoading = payload
    },
    updateAssemblyParts(state: IAssemblyServiceState, payload: KV | null) {
      state.assemblyParts = payload
    },
    updateOrderedInfo(state: IAssemblyServiceState, payload: KV) {
      state.assemblyCheckedInfo = { ...state.assemblyCheckedInfo, ...payload }
    },
    updateCheckedInfo(state: IAssemblyServiceState, payload: KV) {
      const { assemblySubTotalPrice = 0, itemNO = '', quantity = 0, isPat } = payload
      let { checkedItemNOArr = [], totalPrice, withoutCount } = state.assemblyCheckedInfo
      const checkedIndex = checkedItemNOArr.findIndex((item) => item === itemNO)
      if (checkedIndex > -1) {
        checkedItemNOArr = lodash.without(checkedItemNOArr, itemNO)
        if (assemblySubTotalPrice) {
          totalPrice -= assemblySubTotalPrice
        } else {
          withoutCount -= quantity
        }
        const hasPatService = checkedItemNOArr.some(itemNO => (state.bookingDetail?.serviceReferenceItems?.find(item => item.itemNO === itemNO))?.isPat)
        state.assemblyCheckedInfo = { checkedItemNOArr, totalPrice, withoutCount, hasPatService }
      } else {
        checkedItemNOArr = lodash.concat(checkedItemNOArr, itemNO)
        if (assemblySubTotalPrice) {
          totalPrice += assemblySubTotalPrice
        } else {
          withoutCount += quantity
        }
        state.assemblyCheckedInfo = { checkedItemNOArr, totalPrice, withoutCount, hasPatService: isPat ? true : state.assemblyCheckedInfo.hasPatService }
      }
    },
    updateCheckedItemNOAll(state: IAssemblyServiceState) {
      if (state.bookingDetail?.serviceReferenceItems?.length === state.assemblyCheckedInfo?.checkedItemNOArr.length) {
        state.assemblyCheckedInfo = {
          checkedItemNOArr: [],
          totalPrice: 0,
          withoutCount: 0,
          hasPatService: false
        };
      } else {
        const temp: IAssemBlyCheckedInfo = {
          checkedItemNOArr: [],
          totalPrice: 0,
          withoutCount: 0,
          hasPatService: false
        };
        (state.bookingDetail?.serviceReferenceItems || []).forEach(item => {
          const { assemblySubTotalPrice = 0, itemNO = '', quantity = 0, isPat } = item
          temp.checkedItemNOArr.push(itemNO)
          if (isPat) {
            temp.hasPatService = true
          }
          if (assemblySubTotalPrice) {
            temp.totalPrice += assemblySubTotalPrice
          } else {
            temp.withoutCount += quantity
          }
        });
        state.assemblyCheckedInfo = temp;
      }
    },
    reset(state: IAssemblyServiceState) {
      state.hasAssemblyService = false
      state.bookingDetail = null
      // state.assemBlyServiceModalShow = false
      state.assemblyParts = null
      state.assemblyCheckedInfo = {
        checkedItemNOArr: [],
        totalPrice: 0,
        withoutCount: 0,
        hasPatService: false
      }
    },
    updateBookingStatue(state: IAssemblyServiceState) {
      if (state.bookingDetail) {
        state.bookingDetail.status = 'DONE'
        state.bookingDetail.serviceReferenceItems = state.bookingDetail.serviceReferenceItems?.filter(item => state.assemblyCheckedInfo.checkedItemNOArr.findIndex(temp => temp === item.itemNO) > -1) || null
      }
    }
  },
  actions: {
    async initAssemblyServiceInfo({ commit, dispatch }, payload) {
      try {
        const {
          ikeaOrderNO,
          orderNO,
          deliveryInfos = [],
          patService,
          orderStatus,
          orderType,
          orderChannel
        } = payload
        // patService订单
        if (patService) {
          commit('updateHasAssemblyService', true)
          commit('updateBookingDetail', {
            isPatService: true,
            ...payload.patService
          })
          commit('updateOrderedInfo', {
            totalPrice: payload.patService?.netAmount || 0,
            withoutCount: 0
          })
          return
        }
        // cshService订单
        if (deliveryInfos && Array.isArray(deliveryInfos)) {
          const has = deliveryInfos.some((pkg) => {
            return pkg.deliveryItems.some(item => item && item.hasAssembleService)
          })
          if (has && 
            [EOrderStatus.CANCELED, EOrderStatus.WAITING_FOR_PAYMENT].indexOf(orderStatus) === -1 &&
            orderType !== 'CSC' // &&
            // orderChannel !== OrderChannel_Enums.OFFLINE
          ) {
            commit('updateHasAssemblyService', true)
            dispatch('getBookingDetailV2', { ikeaOrderNO, orderNO, orderChannel })
          }
        }
      } catch (e) {
        Sentry.captureException(new Error(JSON.stringify(e)));
      }
    },
    async getBookingDetailV2({ commit }, payload) {
      try {
        const { data: { attachedAssemblyService, patServiceInOrder, deliveryContactInfo } } = await assemblyService.getBookingDetailV2(payload)
        const serviceDetail = patServiceInOrder ?? attachedAssemblyService
        commit('updateBookingDetail', {
          isPatService: !!patServiceInOrder,
          ...serviceDetail
        })
        commit('updateDeliveryContactInfo', deliveryContactInfo)
        commit('updateOrderedInfo', {
          totalPrice: serviceDetail?.netAmount || 0,
          withoutCount: serviceDetail?.noneCalcPriceCnt || 0
        })
        commit('updatePageLoading', false)
      } catch (e) {
        Sentry.captureException(new Error(JSON.stringify(e)));
      }
    },
    submitBookingService({ state }, payload) {
      return new Promise((resolve, reject) => {
        const details = state.assemblyCheckedInfo.checkedItemNOArr.map(itemNO => {
          const checkedItem = state.bookingDetail.serviceReferenceItems.find(item => itemNO === item.itemNO)
          if (checkedItem) {
            return {
              itemNO,
              quantity: checkedItem.quantity
            }
          }
        })
        assemblyService.assemblyBooking({
          details,
          ...payload
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}
