export default {
  waiting_for_payment: 'Unpaid',
  waiting_for_service: 'Service',
  waiting_for_delivery: 'Processing',
  waiting_for_receive: 'Shipped',
  waiting_for_postreview: 'Review',
  waiting_for_pickup: 'Picking Up',
  paid: 'Paid',
  completed: 'Completed',
  canceled: 'Cancelled',
  collected: 'Collected',
}
