<template>
  <div
    v-if="refStatus"
    :class="
      `${classNames('order-status',
        `order-status__${refStatus.toLowerCase()}`, {
        'order-status-debug': debug
      })}`
    "
  >
    <dl>
      <dt>
        <h2
          :class="`${classNames('order-status-text', {
            'order-status-text-with-price': !!slots.price
          })}`"
        >
          <strong>{{refStatusText}}</strong>
          <span v-if="!!slots.price">
            <slot name="price"></slot>
          </span>
        </h2>
      </dt>
      <dd v-if="slots.extras">
        <slot name="extras"></slot>
      </dd>
    </dl>

    <div class="stat-icon">
      <ik-icon
        :name="`order-stat-${refStatus.toLowerCase()}`"
        :size="'100px'"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import classNames from 'classnames'
import Icon from '../Icon/index.vue'
import { computeStatus } from './utils'

export default defineComponent({
  components: {
    IkIcon: Icon,
  },
  props: {
    debug: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'WAITING_FOR_PAYMENT'
    },
    remain: {
      type: Number,
      default: 0
    },
    deliveryOption: {
      type: String,
      default: ''
    },
    hasReviewed: {
      type: Boolean,
      default: false,
    },
    hasExceededReviewTimeLimit: {
      type: Boolean,
      default: false,
    },
    isSimpleServiceOrder: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'plain'
    }
  },
  setup(props, context) {
    props = reactive(props);

    const slots = context.slots;

    const computed = computeStatus({ ...props }, 'rich')
    const refStatus = ref(computed.status)
    const refStatusText = ref(computed.statusText)

    return {
      slots,
      classNames,
      refStatus,
      refStatusText
    }
  },
})
</script>

<style lang="less" scoped>
.order-status {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 105px;
  padding: 0 16px;
  color: #fff;
  // background-color: #959595;
  background-color: #0058A3;

  &__canceled {
    background-color: #959595;
  }

  &-text {
    &-with-price {
      strong {
        &::after {
          content: '：';
        }
      }
    }
  }

  dl {
    dt {
      h2 {
        font-size: 18px;
        display: flex;
        align-items: center;
      }
    }
    dd {
      // font-size: 12px;
      // display: flex;
      // align-items: center;
    }
  }

  .stat-icon {
    position: absolute;
    top: 1.6rem;
    right: 2rem;
    opacity: .3;
  }
}
</style>
