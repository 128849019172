import { LOCAL_STORAGE_KEYS } from '@/enums'
import { IStoreList } from '@/types/stores'
import http from '@/utils/http'
import { merge } from 'lodash'

const getAllStores = async (params = { lang: 'zh-cn' }) => {
  const cachedStores = window.localStorage && JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEYS.ORDERS_STORES_LIST) ?? '{}')
  if (cachedStores && cachedStores.data && cachedStores.data[params.lang]) {
    if (new Date().valueOf() - cachedStores.timestamp > 60 * 60 * 1000) {
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ORDERS_STORES_LIST)
    } else {
      console.log('stores data from localStroage service:', new Date().valueOf() - cachedStores.timestamp)
      return cachedStores.data[params.lang]
    }
  }
  return await http.get('FETCH_ALL_STORES', {
    params: {
      t: new Date().valueOf()
    }
  }).then((res: any) => {
    console.log('stores data from http service')
    const stores: IStoreList = []
    res.data.forEach(p => {
      const pStores: IStoreList = p.stores
      stores.push(...pStores)
    });

    window.localStorage && window.localStorage.setItem(LOCAL_STORAGE_KEYS.ORDERS_STORES_LIST, JSON.stringify(merge(cachedStores, {
      timestamp: new Date().valueOf(),
      data: {
        [params.lang]: stores
      }
    })))

    return stores
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

function storeService () {
  return {
    getAllStores,
  }
}

export default storeService()
