import { rescheduleService } from '@/service'
const getDefaultState = () => {
  return {
    params: {},
    status: '',
    data: null,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateChangeTimeWindow(state, newState) {
      state = newState
    },
    resetChangeTimeWindow(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    /**
     * @description 提交改期申请
     */
    async submitChangeTimeWindow({ state, commit }, params) {
      commit('resetChangeTimeWindow')
      state.status = 'PENDING'
      state.params = params
      commit('updateChangeTimeWindow', state)

      const changeTimeWindowRes = await rescheduleService.changeTimeWindow(params)

      if (changeTimeWindowRes instanceof Error) {
        state.status = 'REJECTED'
        state.data = changeTimeWindowRes
      } else {
        if (changeTimeWindowRes.code === '0') {
          state.status = 'FULFILLED'
          state.data = changeTimeWindowRes
        } else {
          state.status = 'REJECTED'
          state.data = changeTimeWindowRes
        }
      }
      commit('updateChangeTimeWindow', state)
    },
  }
}
