import ikea from 'ikea-jssdk';
import type { PathConfigType } from "ikea-jssdk"

const navigateTo = function (options: PathConfigType | string) {
  try {
    if (typeof options === 'string') {
      ikea.navigateTo(options);
    } else {
      const { smp } = options;
      if (smp) {
        if (smp.path.startsWith("@command_h5path")) {
          ikea.navigateTo(options)
        } else if (smp.path.startsWith("/subpages/login/pages/wx_login/login")) {
          const url = "/pages/login/login";
          ikea.wxJssdkLoaded.then((wx: any) => {
            wx.miniProgram.navigateTo({ url })
          })
        } else {
          console.error("navigateTo not supported at the moment in scan-go: ", options)
        }
      } else {
        console.log('smp is undefined')
      }
    }
  } catch (error) {
    console.error("navigateTo error in scan-go: ", error, options)
  }
}

export default navigateTo
