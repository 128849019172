import http from '@/utils/http'

const firstRegister = async() => {
  return await http.get('FIRST_REGISTER').then((res) => {
    return res.data || {}
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

const getAppDownloadGuide = async() => {
  return await http.get('APP_DOWNLOAD_GUIDE').then((res) => {
    return res.data || {}
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

function yhtService() {
  return {
    firstRegister,
    getAppDownloadGuide,
  }
}

export default yhtService()
