<template>
  <div :class="classes" :style="style">
    <div :class="`${classNames('left', {
      'left-grow': leftGrow
    })}`">
      <slot name="left" v-if="slots.left">{{left}}</slot>
    </div>
    <div :class="`${classNames('right', {
      'right-grow': rightGrow
    })}`">
      <slot name="right" v-if="slots.right">{{right}}</slot>
    </div>
  </div>
</template>

<script>
import './index.less'
import { reactive, computed } from 'vue'
import classNames from 'classnames'

export default {
  name: 'ik-space-between',

  props: {
    style: {
      type: Object,
    },
    leftGrow: {
      type: Boolean,
      default: false,
    },
    rightGrow: {
      type: Boolean,
      default: false,
    }
  },

  setup(props, context) {
    props = reactive(props);

    const slots = context.slots;

    return {
      classNames,
      slots,
      classes: computed(() => ({
        'ik-space-between': true,
      })),
    }
  },
};
</script>
