import bridge from '@/bridge'
import { API_LIST, BASE_URL } from '@/config/http.config'
import { exposeResponse, exposeResponseError } from '@/utils'
import Store from '@/store/index'
import { Origin_Enums } from '@/enums'
import lodash from 'lodash'
import * as Sentry from '@sentry/vue'

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export type IAxiosInstance = {
  headers: {
    [key: string]: string
  },
  withToken?: boolean
}

export interface KV {
  [key: string]: any
}

interface IExtraParam {
  needCancelLast?: boolean
  needCache?: boolean
  [key: string]: any
}

export type IResponse = {
  status: number
  data?: any
}

export interface IHttp {
  /**
   * get('USER_INFO', payload).then((data) => {
   *   // do something
   * })
   */
  get: <T>(apiKeyOrUri: string, config?: AxiosRequestConfig, configExtend?: AxiosRequestConfig) => Promise<T>
  /**
   * since **delete** is js reserved word, rename to **del**
   *
   * ```
   * del('SOME_THING')
   * ```
   */
  del: <T>(apiKeyOrUri: string, config?: AxiosRequestConfig, configExtend?: AxiosRequestConfig) => Promise<T>
  head: <T>(apiKeyOrUri: string, config?: AxiosRequestConfig, configExtend?: AxiosRequestConfig) => Promise<T>
  /**
   * post('NOTE', { ... }, {
   *   resourceId: 1234
   * })
   */
  post: <T>(apiKeyOrUri: string, data?: any, config?: AxiosRequestConfig) => Promise<T>
  put: <T>(apiKeyOrUri: string, data?: any, config?: AxiosRequestConfig) => Promise<T>
  patch: <T>(apiKeyOrUri: string, data?: any, config?: AxiosRequestConfig) => Promise<T>
}

const POST_METHODS = ['POST', 'PUT', 'PATCH']
const GET_METHODS = ['GET', 'DELETE', 'HEAD']

if(new URLSearchParams(window.location.search).get('origin') === Origin_Enums.WE_CHAT_WORK) {
  axios.defaults.withCredentials = true;
}

export let axiosInstance: AxiosInstance = axios.create({})

export const initAxiosInstance = (payload: IAxiosInstance) => {
  const { headers, withToken = true } = payload
  axiosInstance = axios.create()
  bridge.request.axiosPlugin(axios, {
    withToken,
    includeRiskControlHeaders: true,
    headers
  })
  configure({ baseURL: BASE_URL, apiList: API_LIST })
}

const config = {
  baseURL: undefined,
  apiList: undefined,
}

const URI_PROTOCOL = /^https?:/

const PLACEHOLDER = /\$?\{([^}]+)\}/g

function checkApiItem(apiItem, apiKey) {
  const prefix = `[Http Exception] ${apiKey}`

  if (typeof apiItem !== 'string') {
    throw new Error(`${prefix} should be a string`)
  }

  if (!isFullUri(apiItem) && apiItem.charAt(0) !== '/') {
    throw new Error(`${prefix} should be start with "/"`)
  }
}

function checkApiList(apiList) {
  if (typeof apiList !== 'object') {
    throw new Error(`[Http Exception] apiList must be a map, but now: ${typeof apiList}`)
  }

  for (const key in apiList) { // eslint-disable-line no-restricted-syntax
    if (apiList.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
      checkApiItem(apiList[key], key)
    }
  }
}

export function configure({ baseURL, apiList }) {
  if (baseURL) {
    const buildEnv = import.meta.env.VITE_APP_BUILD_ENV || 'prod'
    config.baseURL = baseURL[buildEnv]
  }
  if (apiList) {
    checkApiList(apiList)

    if (typeof config.apiList !== 'undefined') {
      throw new Error('[Http Warning] apiList defined more than once')
    }
    config.apiList = apiList
  }
}

function isFullUri(apiKey) {
  return /^\/\//.test(apiKey) || URI_PROTOCOL.test(apiKey)
}

function replacePlaceholder(targetString, data = {}, params = {}) {
  return targetString.replace(PLACEHOLDER, (matched, key) => {
    if (params && params[key]) {
      return params[key]
    } if (data && data[key]) {
      return data[key]
    }

    return 'undefined'
  })
}

export function makeUri(apiKey, options) {
  let apiUri = ''
  if (isFullUri(apiKey)) {
    apiUri = apiKey
  } else if (typeof config.apiList !== 'undefined' && config.apiList[apiKey]) {
    apiUri = concatBaseURL(config.apiList[apiKey], config)
  } else {
    throw new Error(`[Http Exception] ${apiKey} is not specified on api-list.config`)
  }
  apiUri = replacePlaceholder(apiUri, options.data, options.params)
  return apiUri
}

export function makePath(apiKey) {
  if (isFullUri(apiKey)) {
    return apiKey
  } if (typeof config.apiList !== 'undefined' && config.apiList[apiKey]) {
    return config.apiList[apiKey]
  }

  throw new Error(`[Http Exception] ${apiKey} is not specified on api-list.config`)
}

function concatBaseURL(apiUri, options) {
  if (options.baseURL) {
    return `${options.baseURL}${apiUri}`
  }
  if (typeof config.baseURL === 'undefined') {
    return apiUri
  }
  return config.baseURL + apiUri
}

let hasGotoLogin = false

function processSend(send, originalConfig) {
  const config = originalConfig
  const response = null

  const requestHandler = {
    fulfilled: inChainConfig => {
      // the config content will be change during processes, so keep the reference in the top of function
      if (inChainConfig !== config) {
        throw new Error('[Http Warning] config reference has been changed during dispatch interceptor')
      }

      const sendPromise = send(inChainConfig)

      if (!sendPromise || !sendPromise.then) {
        throw new Error('[Http Exception] send must return a promise object')
      }

      return sendPromise
    },
  }

  const responseDataHandler = {
    fulfilled: (resData: IResponse, res) => {
      // TODO 错误处理
      if (resData.status === 200 && (resData.data.code === '0' || !resData.data.code && resData.status)) {
        exposeResponse({
          path: config.path,
          params: config.data,
          res: resData
        })
      } else {
        if (resData.status === 401) {
          bridge.navigateTo('https://ikeaapp/account/login')
        }
        exposeResponseError(resData.data, {
          params: config.data,
          path: config.path
        })

        Sentry.withScope(scope => {
          scope.setTag("api", config.url);
          Sentry.captureException(new Error(`[HTTP BUSINESS ERROR]: ${resData.status}`));
        });
      }
      return resData as IResponse
    },
    rejected: async (_err) => {
      if (_err?.response?.status === 401 && !hasGotoLogin) {
        hasGotoLogin = true
        bridge.navigateTo('https://ikeaapp/account/login')
      }
      if (_err?.message && _err.message !== 'request cancel') {
        Sentry.withScope(scope => {
          scope.setTag("api", config.path);
          scope.setExtra('requestConfig', config)
          scope.setExtra('requestError', _err)
          Sentry.captureException(new Error(_err.message));
        });
        await exposeResponseError(_err, {
          params: config.data,
          path: config.path
        })
      }
      Sentry.captureException(_err);
      throw _err
    },
  }

  const chain = [
    requestHandler,
    responseDataHandler,
  ]

  let promise = Promise.resolve(config)

  const wrapFulfilled = handler => payload => (handler ? handler(payload, response, config) : payload)

  const wrapRejected = handler => ex => {
    if (handler) {
      return handler(ex, response, config)
    }

    throw ex
  }

  while (chain.length) {
    const task = chain.shift()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise = promise.then(wrapFulfilled(task.fulfilled), wrapRejected(task.rejected))
  }

  return promise
}

const sendFactory = (method, request, interceptors, withData) => (apiKeyOrUri, arg1, arg2) => {
  if (!apiKeyOrUri) {
    return Promise.reject(
      new Error('[Http Exception] apiKey is empty')
    )
  }

  let config

  if (withData) {
    // call like post('api', data, config)
    config = arg2 || {}
    config.data = arg1
  } else {
    // call like get('api', config)
    config = arg1 || {}

    // for some legacy cases, it may be call like get('api', config, config), just handle it
    if (typeof arg2 === 'object') {
      Object.assign(config, arg2)
    }
  }

  config.method = method
  config.url = makeUri(apiKeyOrUri, config)
  config.path = makePath(apiKeyOrUri)
  // config.matchedPath = getMatchedPath(apiKeyOrUri)

  if (!config.headers) {
    config.headers = {}
  }

  return processSend(request, config)
}

export function factory(request) {
  const http = {}

  const interceptors = {
    // request: createInterceptor(), // before send
  }

  POST_METHODS.forEach(method => {
    http[method.toLowerCase()] = sendFactory(
      method, request, interceptors, true
    )
  })

  GET_METHODS.forEach(method => {
    http[method.toLowerCase()] = sendFactory(
      method, request, interceptors, false
    )
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  http.del = http['delete'] //eslint-disable-line
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  http.interceptors = interceptors
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  http.makeUri = makeUri

  // TODO, add cancellable method

  return http
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const http: IHttp = factory(axios.request.bind(axios))

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CancelToken = axios.CancelToken
const sourceMap = {}

function cancelLastRequest(key: string, methods: string) {
  const storeKey = `${key}_${methods}`
  const source = sourceMap[storeKey]
  if (source) {
    source.cancel('request cancel')
  }
}

function getNewCancelToken(key: string, methods: string) {
  const storeKey = `${key}_${methods}`
  sourceMap[storeKey] = CancelToken.source()
  return sourceMap[storeKey].token
}

function request(method: string, key: string, param?: KV, extraParam?: IExtraParam) {
  let fetcher: <T>(apiKeyOrUri: string, config?: any, configExtend?: any) => Promise<T>
  if (method === 'get') {
    fetcher = http.get
  } else if (method === 'post') {
    fetcher = http.post
  } else if (method === 'del') {
    fetcher = http.del
  } else if (method === 'put') {
    fetcher = http.put
  }

  let cancelToken: any
  if (extraParam?.needCancelLast) {
    cancelLastRequest(key, method)
    cancelToken = getNewCancelToken(key, method)
  }

  const handleFetcher = () => fetcher(key, param, {
    ...extraParam,
    cancelToken,
  }).then((data: any) => {
    return data
  })

  return new Promise<any>((resolve, reject) => {
    const promiseHandle = () => handleFetcher().then((data: any) => {
      resolve(data)
    }).catch((e: any) => {
      reject(e)
    })
    promiseHandle()
  })
}

export function get(key: string, param?: KV, extraParam?: IExtraParam) {
  return request('get', key, param, extraParam)
}
export function post(key: string, param?: KV, extraParam?: IExtraParam) {
  return request('post', key, param, extraParam)
}
export function del(key: string, param?: KV, extraParam?: IExtraParam) {
  return request('del', key, param, extraParam)
}
export function put(key: string, param?: KV, extraParam?: IExtraParam) {
  return request('put', key, param, extraParam)
}

export default {
  get,
  post,
  del,
  put
}
