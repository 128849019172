<template>
  <div :class="classes" :style="style">
    <section class="mask">
      <slot name="mask">{{mask}}</slot>
    </section>
    <header v-if="slots.header">
      <slot name="header">{{header}}</slot>
    </header>
    <main id="main-content">
      <slot name="main">{{main}}</slot>
    </main>
    <footer v-if="slots.footer">
      <slot name="footer">{{footer}}</slot>
    </footer>
  </div>
</template>

<script>
import './index.less';
import { reactive, computed, VueElement, onMounted, ref, watch } from 'vue';

export default {
  name: 'ik-layout',

  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    header: {
      type: VueElement,
    },
    main: {
      type: VueElement,
    },
    footer: {
      type: VueElement,
    },
    mask: {
      type: VueElement,
    },
    style: {
      type: String,
    },
    backgroundColor: {
      type: String,
    }
  },

  setup(props, context) {
    props = reactive(props)

    const slots = context.slots

    const scrollTop = ref(0)
    const scrollDirection = ref('')

    onMounted(() => {
      document.body.style.backgroundColor = props.backgroundColor ?? '#FFFFFF';
      const mainContent = document.querySelector('#main-content')
      scrollTop.value = mainContent.scrollTop

      mainContent.addEventListener('scroll', (e) => {
        scrollDirection.value = e.target.scrollTop > scrollTop.value ? 'UP' : 'DOWN'
        scrollTop.value = e.target.scrollTop
      })
    })

    watch(scrollTop, (top) => {
      document.body.style.backgroundColor = top > 0 ? '#EFEFEF' : (props.backgroundColor ?? '#FFFFFF');
    })

    // watch(scrollDirection, (direction) => {
    //   if (direction === 'UP') {
    //     document.body.style.backgroundColor = '#EFEFEF';
    //   }
    //   if (direction === 'DOWN') {
    //     document.body.style.backgroundColor = '#0158A3';
    //   }
    // })

    return {
      slots,
      classes: computed(() => ({
        'ik-layout': true,
        'ik-layout--primary': props.primary,
        'ik-layout--popup': !props.popup,
      })),
    }
  },
};
</script>
