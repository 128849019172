<template>
  <div class="notice-wrapper">
    <ik-card :folded="false">
      <template #header>
        <header>
          <ik-space-between>
            <template #left>
              <ik-flex-cell>
                <template #left v-if="slots.label">
                  <label class="notice-label">
                    <slot name="label"></slot>
                  </label>
                </template>
                <template #right>
                  <div class="notice-content">
                    <slot name="content"></slot>
                  </div>
                </template>
              </ik-flex-cell>
            </template>
            <template #right v-if="slots.extras">
              <div class="notice-extras">
                <slot name="extras"></slot>
              </div>
            </template>
          </ik-space-between>
        </header>
      </template>
      <template #main>
        <slot name="desc"></slot>
      </template>
    </ik-card>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import FlexCell from '../FlexCell/index.vue'
import SpaceBetween from '../SpaceBetween/index.vue'
import Card from '../Card/index.vue'

export default defineComponent({
  components: {
    IkFlexCell: FlexCell,
    IkSpaceBetween: SpaceBetween,
    IkCard: Card,
  },
  setup(props, context) {
    props = reactive(props);

    const slots = context.slots;

    return {
      slots,
    }
  },
})
</script>

<style lang="less" scoped>
.notice-wrapper {
  border-radius: 4px;
  // border: 1px solid #f00;
  margin: 8px 0;
  header {
    margin: 0;
    padding: 0;
    border-radius: transparent;
    background-color: #F5F5F5;
    padding: 8px 10px;
    border-radius: inherit;
  }

  .notice-label {
    font-size: 12px;
    color: #666;
    margin-right: 10px;
  }

  .notice-content {
    color: #111;
    font-weight: 600;
    font-size: 12px;
  }
}
</style>
