<template>
  <div :class="classes" :style="style">
    <div class="left">
      <slot name="left" v-if="slots.left">{{left}}</slot>
    </div>
    <div class="right">
      <slot name="right" v-if="slots.right">{{right}}</slot>
    </div>
  </div>
</template>

<script>
import './index.less';
import { reactive, computed } from 'vue';

export default {
  name: 'ik-flex-cell',

  props: {
    style: {
      type: Object,
    }
  },

  setup(props, context) {
    props = reactive(props);

    const slots = context.slots;

    return {
      slots,
      classes: computed(() => ({
        'ik-flex-cell': true,
      })),
    }
  },
};
</script>
