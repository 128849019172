export default {
  add_to_cart: '加入购物袋',
  repurchase: '再次购买',
  cancel_order: '取消订单',
  go_payment: '继续支付',
  post_review: '去晒单',
  my_reviews: '我的晒单',
  view_logistics: '查看物流',
  view_service: '查看服务',
  self_help_service: '自助售后',
  electronic_receipt: '查看小票',
  online_customer_service: '在线客服',
  apply_invoice: '申请发票',
  view_invoice: '查看发票',
  go_to_refund_detail: '退款成功',
  copy: {
    success: '订单编号复制成功',
    fail: '订单编号复制失败',
  },
  invoice_switch_close: '尊敬的顾客您好，因双十一期间发票业务激增，开票系统出现故障，暂时无法开票，我们正在全力抢修，预计一周左右恢复，请稍后再扫码开票，给您造成的不便我们感到非常抱歉，感谢您的理解与支持，宜家祝您生活愉快！',
  invoice_card_pay: '当前订单完全由卡包支付，无法开票'
}
