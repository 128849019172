const goToPayment = function(options) {
  try {
    const url = `/pages/payment/payment?bizType=${options.bizType}&storeCode=${options.storeCode}&orderId=${options.ikeaOrderNO}&bookingCaseId=${options.checkoutId}`;
    if (window.wx) {
      window.wx.miniProgram.navigateTo({ url });
    } else {
      console.error("scan-go go to payment without window.wx:", options)
    }
  } catch (error) {
    console.error("scan-go go to payment error:", error, options)
  }
}
export default goToPayment
