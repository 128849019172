
import { PageStatus_Enums } from '@/enums/order'
import { ordersService } from '@/service'
import moment from 'moment'

const getDefaultState = () => {
  return {
    status: PageStatus_Enums.LOADING,
    refundData: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateStatus(state, payload) {
      state.status = payload
    },
    updateRefundDate(state, payload) {
      // state.refundData = {
      //   paymentBrand: payload?.paymentBrand,
      //   orderNo: payload?.orderNo,
      //   ikeaOrderNo: payload?.ikeaOrderNo,
      //   refundAmount: `¥${payload?.refundAmount}`,
      //   updateTime: moment(payload?.updateTime).format('YYYY-MM-DD HH:mm')
      // }
      // https://jira.digital.ingka.com/browse/CNODI-2143
      const refundData = (payload || []).map(item => {
        return {
          paymentBrand: item?.paymentBrand,
          orderNo: item?.orderNo,
          ikeaOrderNo: item?.ikeaOrderNo,
          refundAmount: `¥${item?.refundAmount}`,
          updateTime: moment(item?.updateTime).format('YYYY-MM-DD HH:mm')
        }
      })
      state.refundData = refundData
    }
  },
  actions: {
    /**
     * @description 获取退款详情
     * @param orderNo 订单编号
     */
    fetchOrderRefundData({ state, commit }, payload) {
      const { orderId } = payload
      if (orderId) {
        ordersService.getOrderRefundDetail({ orderId }).then(res => {
          if (res.code === '0' && res.data) {
            commit('updateRefundDate', res.data)
            commit('updateStatus', PageStatus_Enums.SUCCESS)
          } else {
            commit('updateStatus', PageStatus_Enums.ERROR)
          }
        }).catch(err => {
          commit('updateStatus', PageStatus_Enums.ERROR)
          console.log(err)
        })
      }
    }
  }
}
