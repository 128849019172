<template>
  <ik-space-between class="ik-space-between-cell">
    <template #left>
      <div class="label">
        <slot name="label"></slot>
      </div>
    </template>
    <template #right>
      <div class="value">
        <slot name="value"></slot>
      </div>
    </template>
  </ik-space-between>
</template>

<script>
import { defineComponent } from 'vue'
import SpaceBetween from './index.vue'

export default defineComponent({
  components: {
    IkSpaceBetween: SpaceBetween,
  },
  setup() {
    return {}
  },
})
</script>

<style lang="less" scoped>
.ik-space-between-cell {
  // border: 1px solid #000;
  padding: .25rem 0;
}
.label {
  color: #666;
  font-size: 14px;
  font-weight: 400;
}
</style>
