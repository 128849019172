// import { EOrderStatus, OrderChannel_Enums } from '@/enums/order'
import { trackAndTraceService } from '@/service'
import * as Sentry from '@sentry/vue'
import { remove } from 'lodash'
// import { Transport_Method_NAME } from '@/enums/track-and-trace'
import { lang } from '@/locales'

export interface KV {
  [key: string]: any
}

export interface IFooter {
  sprouted: boolean
  opened: boolean
}

export interface ITab {
  key: string
}

export interface ICurrent {
  tab: ITab
  footer: IFooter
}

export interface ITrackAndTraceInfo {
  shipmentId: string
}

export interface ITrackAndTraceInfoState {
  trackAndTraceInfo: KV | null
  current: KV | null
  pageLoading: boolean
  bizTab: string
  bizType: string
}

const getDefaultState = (): ITrackAndTraceInfoState => {
  return {
    current: {
      sheet: {
        opened: false,
      },
      footer: {
        sprouted: false,
        opened: false,
      },
      mask: {
        on: false,
      },
      tab: {
        key: '',
      }
    },
    trackAndTraceInfo: null,
    pageLoading: true,
    bizTab: '',
    bizType: '',
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateCurrent(state: ITrackAndTraceInfoState, payload: ICurrent) {
      state.current = { ...state.current, ...payload }
    },
    updatePageLoading(state: ITrackAndTraceInfoState, payload: boolean) {
      state.pageLoading = payload
    },
    updateTrackAndTraceInfo(state: ITrackAndTraceInfoState, payload: ITrackAndTraceInfo) {
      state.trackAndTraceInfo = payload
    },
    updateBizTab(state: ITrackAndTraceInfoState, payload: string) {
      state.bizTab = payload
    },
    updateBizType(state: ITrackAndTraceInfoState, payload: string) {
      state.bizType = payload
    },
  },
  actions: {
    async getTrackAndTraceInfo({ commit }, payload) {
      try {
        const trackAndTraceInfo = await trackAndTraceService.getTrackAndTraceInfo(payload)
        // remove(trackAndTraceInfo.data.orderLogisticsByDeliveries, (item: any) => {
        //   return !item.transportMethod
        // })

        commit('updateTrackAndTraceInfo', processData(trackAndTraceInfo, payload.renderMode))
        commit('updatePageLoading', false)
      } catch (e) {
        Sentry.captureException(new Error(JSON.stringify(e)));
      }
    },
  }
}

const processData = (data, renderMode) => {
  if (data instanceof Error) {
    return data
  }

  const transportMethods = { PARCEL: [], TRUCK: [], default: [] }

  const orderLogisticsByDeliveries = data?.data?.orderLogisticsByDeliveries || []; 
  const orderAssemblyInfos = data?.data?.orderAssemblyInfos;

  for (let i = 0; i < orderLogisticsByDeliveries.length; i++) {
    const orderLogistic = orderLogisticsByDeliveries[i]
    orderLogistic.transportMethod = orderLogistic.transportMethod || 'default'
    orderLogistic.transportMehodIndex = transportMethods[orderLogistic.transportMethod].length
    transportMethods[orderLogistic.transportMethod].push(orderLogistic)

    if (orderLogistic.transportMethod === 'TRUCK') {
      orderLogisticsByDeliveries[i] = handleTransportMethod(orderLogistic, transportMethods.TRUCK, renderMode, i)
    } else if (orderLogistic.transportMethod === 'PARCEL') {
      orderLogisticsByDeliveries[i] = handleTransportMethod(orderLogistic, transportMethods.PARCEL, renderMode, i)
    } else {
      orderLogisticsByDeliveries[i] = handleTransportMethod(orderLogistic, transportMethods.default, renderMode, i)
    }
  }
  
  transportMethods.PARCEL.map((item: any, index: number) => {
    return handleTransportMethod(item, transportMethods.PARCEL, renderMode, index)
  })
  transportMethods.TRUCK.map((item: any, index: number) => {
    return handleTransportMethod(item, transportMethods.TRUCK, renderMode, index)
  })
  transportMethods.default.map((item: any, index: number) => {
    return handleTransportMethod(item, transportMethods.default, renderMode, index)
  })

  if (orderAssemblyInfos && orderAssemblyInfos.length) {
    for (let i = 0; i < orderAssemblyInfos.length; i++) {
      const orderAssemblyInfo = orderAssemblyInfos[i]
      orderAssemblyInfos[i] = handleAssemblyInfo(orderAssemblyInfo, renderMode)
    }
  }

  const ret = {
    ...data,
    data: {
      dmpSign: data?.data?.dmpSign || '',
      // orderLogisticsByDeliveries: [].concat(transportMethods.TRUCK).concat(transportMethods.PARCEL),
      orderLogisticsByDeliveries,
      orderAssemblyInfos
    }
  }
  return ret
}

const handleTransportMethod = (item, transportMethod, renderMode, index) => {
  item.hasGPSInfo
    ? (renderMode === 'preview' ? item.mode = 'MapOnly' : item.mode = 'TimelineWithMap')
    : item.mode = 'TimelineOnly'

  item.key = item.workOrderNo || `DEFAULT_${index}`
  item.name = item.transportMethodName = lang.message.track_and_trace.logistics.transportMethods[item.transportMethod]
  
  if (transportMethod.length > 1) item.name = `${item.transportMehodIndex + 1}/${transportMethod.length} ${item.name}`

  for (let i = 0; i < item.orderLogisticsByShipments.length; i++) {
    item.orderLogisticsByShipments[i].name = `${lang.message.track_and_trace.logistics.package.prefix}${i + 1}`
    item.orderLogisticsByShipments[i].key = `shipment-${i}`
  }
  return item
}

const handleAssemblyInfo = (item, renderMode) => {
  item.hasGPSInfo
    ? (renderMode === 'preview' ? item.mode = 'MapOnly' : item.mode = 'TimelineWithMap')
    : item.mode = 'TimelineOnly'

  item.key = item.serviceNumber
  item.name = item.serviceName
  return item
}
