import order from './order'
import orderStatus from './orderStatus'

export default {
  title: '我的订单',
  sources: {
    all: {
      text: '全部订单'
    },
    online: {
      text: '线上商城订单',
    },
    offline: {
      text: '线下商场订单'
    },
    ent: {
      text: '企业管理中心'
    },
  },
  cates: {
    all: '全部',
    ...orderStatus,
    after_sales: '退换',
  },
  order: {
    ...order
  },
  no_orders_yet: '还没有相关订单',
  no_orders_yet_offline: '显示2021年11月至今门店消费记录',
  reach_bottom: '已经到底啦',
  reach_bottom_offline: '显示2021年11月至今门店消费记录',
  reload: '重新加载',
  service_down: '服务器开小差，请稍后再试',
  order_status_popover: '点击切换线下商场订单',
  breadCrumb: {
    items: [
      {
        "url": "/profile/myprofile",
        "text": "个人中心"
      },
      {
        "text": "我的订单"
      },
    ]
  },
  invoice_tip: {
    title: '提示',
    desc: '很抱歉，由于货品仍在配送中无法开具发票，请待送货完成之后再使用此功能开具发票。感谢您的理解与支持。',
    confirmText: '我知道了',
    contact: '如需开票，请前往商场退换货处，联系售后员工为你开票。',
  },
  order_channel_switch: '更多'
}
