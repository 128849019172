<template>
  <div :class="`${classNames('alert-message', {
    'alert-message-info': type === 'info',
    'alert-message-warning': type === 'warning', 
    'alert-message-error': type === 'error',
  })}`">
    <div class="icon"></div>
    <div class="message">
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
// import bridge from '@/bridge'
import { defineComponent, onMounted, ref } from 'vue'
import classNames from 'classnames'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
  },
  props: {
    type: {
      type: String,
      default: 'info'
    }
  }, 
  setup() {
    const store = useStore()
    const { globalState, trackAndTraceState } = store.state
    return {
      classNames
    }
  },
})
</script>

<style lang="less" scoped>
.alert-message {
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;

  .icon {
    width: 1.125rem;
    height: 1.125rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    flex: 0 0 auto;
  }
  .message {
    flex: 1 1 auto;
    font-size: 14px;
    margin: 0 .5rem;
    a {
      color: #0058A3;
    }
  }

  &-info {
    border-left: 4px solid #0058A3;
    .icon {
      background-image: url(https://res.app.ikea.cn/content/u/20231031/a60eb0b9a909476580d40f205d0b1bb3.png);
    }
  }

  &-warning {
    border-left: 4px solid #F26A2F;
    .icon {
      background-image: url(https://res.app.ikea.cn/content/u/20231031/2fe8d1d77eee43b585dab39affd17c17.png);
    }
  }

  // &-error {
  //   border-left: 4px solid #f00;
  //   .icon {
  //     background-image: url(https://res.app.ikea.cn/content/u/20231031/8b5b8b9b9b9b4b6b9b9b9b9b9b9b9b9b.png);
  //   }
  // }

}
</style>
