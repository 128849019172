export default {
  bizTabs: {
    logistics: 'Logistics',
    assembly: 'Assembly'
  },
  logistics: {
    page: {
      title: 'Logistics details',
      empty: 'No logistics information, please check later',
      reload: 'Reload',
      workOrder: {
        shipment: {
          package: {
            title: 'Package Info',
            shipmentIdLabel: 'Shipment ID:',
            carrierNameLabel: 'Carrier name:',
            deliveryTimeLabel: 'Estimated delivery time:'
          },
          deliveryStaffNameLabel: 'Delivery staff',
          deliveryStaffPhoneLabel: 'Phone contact',
          itemsSheet: {
            title: 'Item list',
            close: 'Close',
            countTemplate: '{count} items in total',
            fullCountTemplate: '{count} items in total'
          }
        }
      }
    },
    preview: {
      title: 'Logistics dynamic',
      greenEnergy: 'Green energy delivery',
      viewDetail: 'View details',
    },
    transportMethods: {
      PARCEL: 'Parcel delivery',
      TRUCK: 'Truck delivery',
      default: 'Normal delivery',
    },
    package: {
      prefix: 'Package'
    }
  },
  assembly: {
    page: {
      title: 'Service details',
      empty: 'No service information, please check later',
      reload: 'Reload',
      shipment: {
        service: {
          title: 'Service Info',
          shipmentIdLabel: 'Service number:',
          serviceStatusLabel: 'Service status:',
          appointmentTimeLabel: 'Estimated service time:'
        },
        setterNameLabel: 'Assembly master',
        setterPhoneLabel: 'Phone contact',
        itemsSheet: {
          title: 'Product list',
          close: 'Close',
          countTemplate: '{count} items in total',
          fullCountTemplate: '{count} items in total'
        }
      }
    },
    preview: {
      title: 'Service dynamic',
      viewDetail: 'View details',
    },
    actions: {
      goToTrackAndTrace: 'View service dynamic'
    }
  },
}
