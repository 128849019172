import http from '@/utils/http'

const getBookingDetail = async(params) => {
  return await http.post('FETCH_BOOKING_DETAIL', {
    ikeaOrderNO: params.ikeaOrderNO,
    orderNO: params.orderNO
  }).then((res) => {
    return res.data || {}
    // return res.data && res.data.data || [];
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

const getBookingDetailV2 = async(params) => {
  return await http.post('FETCH_BOOKING_DETAIL_V2', {
    ikeaOrderNO: params.ikeaOrderNO,
    orderNO: params.orderNO,
    orderChannel: params.orderChannel,
  }).then((res) => {
    return res.data || {}
    // return res.data && res.data.data || [];
  }).catch(async err => {
    return new Error(JSON.stringify(err))
  })
}

const assemblyBooking = (params) => {
  return http.post('ASSEMBLY_BOOKING', {
    details: params.details,
    ikeaOrderNO: params.ikeaOrderNO,
    orderNO: params.orderNO,
    orderChannel: params.orderChannel,
  })
}

function assemblyService() {
  return {
    getBookingDetail,
    assemblyBooking,
    getBookingDetailV2
  }
}

export default assemblyService()
