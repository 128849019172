export const currency = (price, opts = {
  formatter: new Intl.NumberFormat('zh-CN', {
    style: 'currency',
    currency: 'CNY',
  }),
  decimal: '.'
}) => {
  const {
    formatter,
    decimal,
  } = opts

  let raw = formatter.format(price).toString()
  if (!raw.startsWith('-')) raw = `+${raw}`

  const [polar, symbol, ...value] = raw;

  const [ints = '0', cents = '00'] = value.join('').split(decimal)

  const ret = {
    raw,
    polar,
    symbol,
    ints,
    cents,
    decimal,
    formatter,
  }

  return ret
}
