// https://www.andiamo.co.uk/resources/iso-language-codes/
import Cookies from 'js-cookie';
import qs from 'qs'
import zh_cn from './zh-cn/index'
import en from './en/index'
const query = qs.parse(location.search.replace(/^\?(.*)/, '$1'))
// compatible for ikeacn
const pathnameLang = /^\/cn\//.test(location.pathname) && (location.pathname).replace(/\/cn\/([a-z]+)\/(.*)/, '$1')
if (pathnameLang) {
  query.lang = pathnameLang === 'en' ? query.lang = 'en' : query.lang = 'zh-cn'
}

// const platform = Cookies.get('platform')
let cookieLang = Cookies.get('ikeacn_lang')
console.log('query.platform:', query.platform)
if (cookieLang === 'zh' || query.platform === 'WechatMiniProgram') {
  cookieLang = 'zh-cn'
}

const langs = {
  'zh-cn': zh_cn,
  en,
}

export const lang = langs[query.lang || cookieLang || 'zh-cn'] ?? langs['zh-cn']
export default langs
