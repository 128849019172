export default {
  waiting_for_payment: '待支付',
  waiting_for_service: '待服务',
  waiting_for_delivery: '待发货',
  waiting_for_receive: '待收货',
  waiting_for_postreview: '待晒单',
  waiting_for_pickup: '待自提',
  paid: '已下单',
  completed: '已完成',
  canceled: '已取消',
  collected: '已收货',
}
