import StatMappings from './mappings'
import { lang } from '../../../locales'

const {
  message: {
    system: {
      orderStatus,
    } = {}
  } = {}
} = lang || {}

export const computeStatus = ({
  status,
  remain,
  isSimpleServiceOrder,
  deliveryOption,
  hasReviewed,
  hasExceededReviewTimeLimit,
}, mode = 'plain') => {
  const ret = {
    status,
    statusText: StatMappings[status]
  }
  switch (status) {
    case 'WAITING_FOR_PAYMENT':
      ret.status = remain > 0 ? 'WAITING_FOR_PAYMENT' : 'CANCELED'
      ret.statusText = StatMappings[ret.status]
      break
    case 'PAID':
      ret.statusText = isSimpleServiceOrder
        ? orderStatus.waiting_for_service
        : orderStatus.waiting_for_delivery
      break
    case 'DELIVERED':
      if (deliveryOption === 'HOME_DELIVERY') {
        ret.statusText = orderStatus.waiting_for_delivery
      }
      if (deliveryOption !== 'HOME_DELIVERY') {
        ret.status = 'WAITING_FOR_PICKUP'
        ret.statusText = orderStatus.waiting_for_pickup
      }
      break
    case 'COMPLETED':
      if (hasReviewed === false && hasExceededReviewTimeLimit === false) {
        ret.status = 'POST_REVIEW'
        ret.statusText = orderStatus.waiting_for_postreview
      }
      if (hasReviewed === true) {
        ret.status = 'POST_REVIEWED'
        ret.statusText = orderStatus.completed // '已完成'
      }
      if (hasExceededReviewTimeLimit === true && !hasReviewed) {
        ret.statusText = orderStatus.completed // '已完成'
      }
      // 纯服务订单:
      if (isSimpleServiceOrder) {
        ret.status = 'COMPLETED'
        ret.statusText = orderStatus.completed // '已完成'
      }
      break
  }

  if (mode === 'rich') {
    switch (ret.status) {
      case 'CANCELED':
      case 'COMPLETED':
        ret.statusText = `订单${ret.statusText}`
        break;
    }
  }

  return ret
}
