export default {
  title: 'Cancellation',
  confirm_title: 'Are you sure to cancel the order?',
  tips: 'Please select the reason you want to cancel your order. Once cancelled, the order cannot be recovered.',
  gitTips: 'Reminder: Some products in the order need to be specially transferred. The order will be delivered together afterward. Please wait patiently. If you continue to cancel the order, please select the reason for cancellation,  it cannot be restored after being canceled successfully.',
  tips_for_paid_order_618: 'Cancellation will revoke all coupons issued by this order. In case any related coupon got redeemed, you’ll see error. Compensation is required, IKEA customer service coworker will contact you for further process, thank you for your understanding.',
  tips_for_paid_order: 'Please be patient after submitting the cancellation request. If the request is approved, the payment will be refunded to your account within 24 hours. If the order has not been cancelled after 30 minutes, please contact customer service.',
  assembly_service: {
    tips: 'Please contact customer service through the following methods to cancel your order. If you have contacted, please wait for the result.',
    call_service: 'Call customer service {phone_number}',
    contact_online_service: 'Contact online customer service',
  },
  reasons: {
    'OC-0401': 'The price is too high',
    'OC-0402': 'The delivery Fee is too high',
    'OC-0403': 'Selected wrong address',
    'OC-0404': 'Selected wrong type',
    'OC-0405': 'Selected wrong delivery date',
    'OC-0406': 'Do not want it any more',
    'OC-0407': 'Others',
    '0501': 'Style or size not fit',
    '0502': 'Bought more than needed',
    '0503': 'Found better product/price elsewhere',
    '1502': 'Late delivery/service',
    '8501': 'Ordered wrong product',
    '8502': 'Incorrect/misleading information'
  },
  actions: {
    confirm: 'Cancel anyway',
    cancel: 'Don’t cancel',
    next_step: 'Next',
    apply: 'ok',
  },
  results: {
    sys_error: 'Server exceptinon',
    do_cancel_success: 'Cancellation successful',
    do_apply_success: 'Application successful',
    cancel_exception_a: 'Error occurred, please try again later',
    cancel_exception_b: 'Error occurred, IKEA customer service will contact you for further processing, thank you',
    cancel_exception_c: 'Error occurred, please contact IKEA customer service for further processing, thank you',
    cancel_exception_d: 'We have received your cancel request, please wait for the result.',
    cancel_exception_e: 'Please contact IKEA customer service for cancel the order, thank you.',
    cancel_exception_f: 'This order has been rebated, please contact customer service to cancel the order.',
  },
  paid: {
    submitted: 'Submitted successfully! Please wait for the processing result.',
    refund: 'The payment will be refunded to your account within 24 hours',
    processing: {
      prefix: 'Cancellation submitted successfully! Please wait for the processing result. If you have any concern, please cantact with customer service.'
    },
    refunded: 'Refund successfully, for more refund detail >',
  },
  moveBack: {
    text: 'All products in this order will be moved back to the shopping bag after cancellation.'
  },
  processing: {
    title: 'Your cancellation request is being processed',
    tips1: 'Refunds will be made through the original payment method within 24 hours upon approval of your order cancellation request. Please check your account for the refund. Otherwise, we will notify you of the review result via a phone call or text message within 24 hours after being informed. Please keep your mobile phone connected and check your text messages regularly. Thank you for your understanding and cooperation.',
    tips2: 'If you have any further questions, you may reach us through the following channels'
  },
  csc: {
    contact_online_service: {
      title: 'Contact online customer service',
      tips: '9:00 - 22:00 (Monday to Sunday)',
    },
    call_service: {
      title: 'Call customer service',
      tips: '9:00 - 18:00 (Monday to Sunday)',      
    }
  },
  ib: {
    error: {
      text: 'Rebate for this order has been processed. To cancel your order, please contact customer service.'
    }
  }
}
