// import { createApp, Component } from 'vue'
import bridge from '@/bridge'
import { Platform_Vars } from '@/enums/index'
import { OrderChannel_Enums } from '@/enums/order'
import Common from '@/ikea-routers/defaultModule'
import Order from '@/ikea-routers/order'
import { toast } from '@ikeacn/ui'
import { Base64, encode } from 'js-base64'
import md5 from 'md5'
import qs from 'qs'
import _orderUtils from './order'
// import { useNavigation } from '@/shared/composables/useNavigation/index';

const query = qs.parse(location.search.replace(/^\?(.*)/, '$1'))

export function toQueryString(query) {
  return qs.stringify(query, { skipNulls: true });
}

export function formatString(str, vars) {
  return (str || '').replace(/\{([^}]+)\}/g, function(match, name) { 
    return vars[name] || "";
  });
}

export const getProductTypeShort = (type) => {
  let t = ''
  if (type === 'SPR') {
    t = 's'
  } else if (type === 'VTP') {
    t = 'v'
  }
  return t
}

export const track = async (name, params, eventKey = '') => {
  const token = await bridge.getToken()
  const { value: { platform } } = await bridge.currentPlatform()
  const paths = location.pathname.split('/')

  if (params.message_var) {
    window.parent.postMessage({
      name: 'MESSAGE_FROM_ORDER',
      type: 'track',
      value: params.message_var
    }, '*')
  }
  try {
    bridge.tracking.track(name, eventKey, {
      timestamp_var: new Date().valueOf(),
      eventName: name,
      ut_var: md5(token.value),
      platform_var: Platform_Vars[platform],
      page_var: paths[paths.length - 1].replace('.html', ''),
      ...params,
    })
  } catch (err) {
    
  }
}

export const getPlatformInfo = async () => {
  const { value: platform } = await bridge.currentPlatform()

  platform.isWeb = [
    'PcWeb',
    'MobileWeb',
    'unknown'
  ].indexOf(platform.platform) > -1

  platform.isApp = ['IOS', 'ANDROID'].indexOf(platform?.platform.toUpperCase()) > -1

  return platform
}

export const exposeResponseError = async(err, extras) => {
  const {
    path,
    params,
  } = extras;

  const { value: { platform } } = await bridge.currentPlatform()
  // const userInfo = await bridge.getUserInfo()
  const { value: token } = await bridge.getToken()
  const data = {
    id_var: 'ERROR_RESPONSE',
    path_var: path,
    params_var: JSON.stringify(params),
    data_var: JSON.stringify(err),
    platform_var: Platform_Vars[platform],
    // user_info_var: JSON.stringify(userInfo),
    token_var: token,
    ua_var: navigator.userAgent,
  }
  if (!query.validationToken) {
    // const userInfo = await bridge.getUserInfo()
    // data.user_info_var = JSON.stringify(userInfo)
  }

  global.tracker.sendTrack(global.trackerConfig.eventName.expose, data)
}

export const exposeResponse = async(extras) => {
  const {
    path,
    params,
    res,
  } = extras;

  const { value: { platform } } = await bridge.currentPlatform()
  // const userInfo = await bridge.getUserInfo()
  const { value: token } = await bridge.getToken()
  const data = {
    id_var: 'HTTP_RESPONSE',
    path_var: path,
    params_var: JSON.stringify(params),
    platform_var: Platform_Vars[platform],
    // user_info_var: JSON.stringify(userInfo),
    token_var: token,
    ua_var: navigator.userAgent,
    res_var: res,
    status_var: res.status,
    code_var: res?.data?.code,
  }
  if (!query.validationToken) {
    // const userInfo = await bridge.getUserInfo()
    // data.user_info_var = JSON.stringify(userInfo)
  }
  global.tracker.sendTrack(global.trackerConfig.eventName.expose, data)
}

// 售后进度/售后详情
export const gotoAfterSalesDetail = async (params) => {
  const { value: platform } = await bridge.currentPlatform()

  const queryParams = {
    ...query,
    orderId: params.orderId,
    ikeaOrderNO: params.ikeaOrderNO,
    afterSalesStatus: params.afterSalesStatus,
    afterSalesServiceType: params.afterSalesServiceType,
    immersed: params.immersed,
  }
  // bridge.navigateTo(`ikea://order/aftersales-detail?${qs.stringify(queryParams)}`)
  if (['iOS', 'ANDROID', 'WechatMiniprogram', 'StoreCompanion'].indexOf(platform?.platform) > -1) {
    bridge.navigateTo(Order.AftersalesDetail(queryParams))
  } else if (['PcWeb', 'MobileWeb'].indexOf(platform?.platform) > -1) {
    if ((/res((\.)|(-local\.)|(-qa\.)|(-uat\.)|(-ctem-))app\.(ikea|ingka-dt)\.cn/).test(window.location.host)) {
      // res自己研发的跳详情页直接跳res
      location.href = `./aftersales-detail.html?${qs.stringify(queryParams)}`
    } else {
      if (['MobileWeb'].indexOf(platform?.platform) > -1) {
        bridge.navigateTo(Order.AftersalesDetail(queryParams))
      }
      if (['PcWeb'].indexOf(platform?.platform) > -1) {
        const hash = `#order=${encodeURIComponent(qs.stringify({ ...queryParams, immersed: true }))}&action=showAfterSales&platform=${platform?.platform}`
        const base = `${window.location.origin}/cn/${window.__IKEACN__.app.getContext().getLang()}/${window.__IKEACN__.app.getContext().getContextPath()}/order`
        // location.replace(`${base}/order-list/#${hash}`)
        window.open(`${base}/order-list/${hash}`, '_blank');
        // router.push({ hash: `#order=${encodeURIComponent(qs.stringify({ ...queryParams, immersed: true }))}&action=showAfterSales&platform=${platform?.platform}` })
      }
    }
  } else {
    if ((/res((\.)|(-local\.)|(-qa\.)|(-uat\.))app\.ikea\.cn/).test(window.location.host)) {
      // res自己研发的跳详情页直接跳res
      location.href = `./aftersales-detail.html?${qs.stringify(queryParams)}`
    } else {
      bridge.navigateTo(Order.AftersalesDetail(queryParams))
    }
  }
}

// gotoOrderDetail for OrderList
export const gotoOrderDetail = async (params) => {
  // const { navitateToOrderDetail } = useNavigation();

  const { value: platform } = await bridge.currentPlatform()

  const {
    router
  } = params
  
  const queryParams = {
    orderId: params.item.orderNO,
    ikeaOrderNO: params.item.ikeaOrderNO,
    immersed: !!params.immersed,
    // orderStatus: props.item.orderStatus,
    orderChannel: params.item.orderChannel,
    extras: params.query.extras,
  }

  if (params.item.orderStatus !== 'WAITING_FOR_PAYMENT') {
    queryParams.orderStatus = params.item.orderStatus
    params.query.orderStatus = params.item.orderStatus
  } else {
    delete queryParams.orderStatus
    delete params.query.orderStatus
  }

  // navitateToOrderDetail(queryParams, router)
  
  if (['iOS', 'ANDROID', 'WechatMiniprogram', 'StoreCompanion'].indexOf(platform?.platform) > -1) {
    // bridge.navigateTo(Order.OrderDetail(queryParams))
    queryParams.extras = encodeURIComponent(queryParams.extras)
    bridge.navigateTo(`ikea://order/order-detail?${toQueryString(queryParams)}`)
  } else if (['PcWeb', 'MobileWeb'].indexOf(platform?.platform) > -1) {
    if ((/res((\.)|(-local\.)|(-qa\.)|(-uat\.)|(-ctem-))app\.(ikea|ingka-dt)\.cn/).test(window.location.host)) {
      // res自己研发的跳详情页直接跳res
      location.href = `./order-detail.html?${toQueryString(queryParams)}`
    } else {
      if (['MobileWeb'].indexOf(platform?.platform) > -1) {
        bridge.navigateTo(Order.OrderDetail(queryParams))
      }
      if (['PcWeb'].indexOf(platform?.platform) > -1) {
        router.push({ hash: `#order=${encodeURIComponent(toQueryString({ ...queryParams, immersed: true }))}&action=showDetail&platform=${platform?.platform}` })
      }
    }
  } else {
    if ((/res((\.)|(-local\.)|(-qa\.)|(-uat\.))app\.ikea\.cn/).test(window.location.host)) {
      // res自己研发的跳详情页直接跳res
      location.href = `./order-detail.html?${toQueryString(queryParams)}`
    } else {
      bridge.navigateTo(Order.OrderDetail(queryParams))
    }
  }
}

export const gotoTrackAndTrace = async (params, _order, pkg) => {
  const { value: platform } = await bridge.currentPlatform()
  console.log('platform..', platform)

  delete query.ikeaOrderNO
  delete query.orderStatus
  delete query.orderChannel
  
  const queryParams = {
    ...query,
    orderId: params.orderId,
    ikeaOrderNo: params.ikeaOrderNO,
    workOrderNo: params.workOrderNo,
    serviceNo: params.serviceNo,
    bizType: params.bizType,
    immersed: params.immersed,
  }
  
  // bridge.navigateTo(`ikea://order/track-and-trace?${qs.stringify(queryParams)}`)
  if (['iOS', 'ANDROID', 'WechatMiniprogram', 'StoreCompanion'].indexOf(platform?.platform) > -1) {
    console.log('----- queryParams -----', queryParams)
    // bridge.navigateTo(Order.TrackAndTraceNext(queryParams))
    // queryParams.extras = encodeURIComponent(queryParams.extras)
    bridge.navigateTo(`ikea://order/track-and-trace-next?${qs.stringify(queryParams)}`)
  } else if (['PcWeb', 'MobileWeb'].indexOf(platform?.platform) > -1) {
    if ((/res((\.)|(-local\.)|(-qa\.)|(-uat\.)|(-ctem-))app\.(ikea|ingka-dt)\.cn/).test(window.location.host)) {
      // res自己研发的跳详情页直接跳res
      location.href = `./track-and-trace.html?${qs.stringify(queryParams)}`
      // bridge.navigateTo(Order.TrackAndTraceNext(queryParams))
    } else {
      if (['MobileWeb'].indexOf(platform?.platform) > -1) {
        bridge.navigateTo(Order.TrackAndTraceNext(queryParams))
      }
      if (['PcWeb'].indexOf(platform?.platform) > -1) {
        const base = `${window.location.origin}/cn/${window.__IKEACN__.app.getContext().getLang()}/${window.__IKEACN__.app.getContext().getContextPath()}/order`
        const hash = `#order=${encodeURIComponent(qs.stringify({ ...queryParams, immersed: true }))}&action=showTrackAndTrace&platform=${platform?.platform}`
        // location.replace(`${base}/order-list/#${hash}`)
        window.open(`${base}/order-list/${hash}`, '_blank');
        // router.push({ hash: hello })
      }
    }
  } else {
    if ((/res((\.)|(-local\.)|(-qa\.)|(-uat\.))app\.ikea\.cn/).test(window.location.host)) {
      // res自己研发的跳详情页直接跳res
      location.href = `./track-and-trace.html?${qs.stringify(queryParams)}`
    } else {
      bridge.navigateTo(Order.TrackAndTraceNext(queryParams))
    }
  }
}

export const callService = async (params, page = 'Order_Detail') => {
  const { value: platform } = await bridge.currentPlatform()
  const isApp = ['IOS', 'ANDROID'].indexOf(platform?.platform.toUpperCase()) > -1
  const isPcWeb = ['PCWEB'].indexOf(platform?.platform.toUpperCase()) > -1

  global.tracker.sendTrack(global.trackerConfig.eventName.clickData, {
    eventType_var: global.trackerConfig.eventType_var.click,
    objectType_var: global.trackerConfig.objectType_var.contactCSC,
  })

  if (isApp) {
    location.href = 'https://ikeaapp/dialPhone?phone=tel:4008002345'
  } else if (isPcWeb) {
    bridge.navigateTo(Common.CustomerService({
      page,
      orderID: params.receiptBarcode || params.ikeaOrderNO,
    }))
  } else {
    location.href = 'tel:4008002345'
  }
}

export const chatService = async (params, page = 'Order_Detail') => {
  global.tracker.sendTrack(global.trackerConfig.eventName.clickData, {
    eventType_var: global.trackerConfig.eventType_var.click,
    objectType_var: global.trackerConfig.objectType_var.chatService,
  })

  bridge.navigateTo(Common.CustomerService({
    page,
    orderID: params.receiptBarcode || params.ikeaOrderNO,
  }))
}

export const contactCustomerService = async (params, page = 'Order_Detail') => {
  global.tracker.sendTrack(global.trackerConfig.eventName.clickData, {
    eventType_var: global.trackerConfig.eventType_var.click,
    objectType_var: global.trackerConfig.objectType_var.contactCSCIcon,
  })

  // https://jira.digital.ingka.com/browse/CNODI-2665
  bridge.navigateTo(Common.CustomerService({
    page,
    orderID: params.receiptBarcode || params.ikeaOrderNO,
  }))
}

export const getViewServiceInfoLink = ({ ikeaOrderNO }) => {
  const YHT_BASE_URL = {
    prod: 'https://yht.ikea.cn',
    qa: 'https://fe-api.ingka-dt.cn/'
  }
  if (ikeaOrderNO) {
    return `${import.meta.env.VITE_APP_BUILD_ENV === 'prod' ? YHT_BASE_URL.prod : YHT_BASE_URL.qa}#/search?id=${ikeaOrderNO}&log_type=3&tab=1`
  }
  return ''
}

export const requestAddToCart = (products, lang) => {
  bridge.request.addToCart({
    products,
  }).then((res) => {
    const {
      data: {
        products = []
      } = {}
    } = res
    const [product = {}] = products

    const {
      subProducts = []
    } = product || {}
    const [subProduct = {}] = subProducts

    toast({
      text: subProduct.message || product.message || lang.message.recommendation.addProductsToShoppingCart.status.successWithCount.replace('{count}', products.length),
      subtle: true,
      horizontalOffset: 0,
      verticalOffset: 44,
    })
    bridge.postMessage({ name: 'refreshShoppingCart' })
  }).catch(() => {
    toast({
      text: lang.message.recommendation.addProductsToShoppingCart.status.failed,
      subtle: true,
      horizontalOffset: 0,
      verticalOffset: 44,
    })
    bridge.postMessage({ name: 'refreshShoppingCart' })
  })
}

export const goToAssemblyService = async (params) => {
  const { platform, query } = params
  
  if (['iOS', 'ANDROID', 'WechatMiniprogram', 'StoreCompanion'].indexOf(platform?.platform) > -1) {
    bridge.navigateTo(`ikea://order/assembly-service?${qs.stringify(query)}`)
  } else if (['PcWeb', 'MobileWeb'].indexOf(platform?.platform) > -1) {
    if ((/res((\.)|(-local\.)|(-qa\.)|(-uat\.)|(-ctem-))app\.(ikea|ingka-dt)\.cn/).test(window.location.host)) {
      // res自己研发的跳详情页直接跳res
      location.href = `./assembly-service.html?${qs.stringify(query)}`
    } else {
      bridge.navigateTo(Order.AssemblyService(query))
    }
  } else {
    if ((/res((\.)|(-qa\.)|(-uat\.))app\.ikea\.cn/).test(window.location.host)) {
      // res自己研发的跳详情页直接跳res
      location.href = `./assembly-service.html?${qs.stringify(params.query)}`
    } else {
      bridge.navigateTo(Order.AssemblyService(query))
    }
  }
}

export const formatCurrency = (num, unit = '¥') => {
  var formattedNum = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${unit}${formattedNum}`
}

export { }
export const orderUtils = _orderUtils
