<template>
  <h3>
    {{$t('message.order_list.order.ikea_order_no', { ikeaOrderNO: value })}}
  </h3>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    value: String
  },
  setup() {
    return {
    }
  },
})
</script>

<style lang="less" scoped>
@import '../../../ikea.order.less';

h3 {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: .875rem;
  color: @gray-7;
}
</style>
