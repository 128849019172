export default {
  bookingBefore: {
    headTitle: '预定宜家组装服务',
    headDesc: '专业、可靠，了解宜家组装服务',
    bannerTitle: '选择需组装服务的商品',
    bannerDesc: '提交后客服给您回电确定组装服务价格和上门时间'
  },
  bookingAfter: {
    pageTitle: '申请成功',
    headTitle: '已提交宜家组装服务预约申请',
    headDesc: '客服将尽快与您联系，请保持电话畅通。',
    bannerTitle: '需组装服务的商品',
  },
  priceTip: {
    a: '预计组装费用合计',
    b: '组装费用合计',
    withoutCount: '{payload}件安装费用未计入（如接电、上墙等）'
  },
  comments: {
    title: '额外费用：基础服务费 ¥{payload}',
    detail: '了解收费明细'
  },
  btnText: '提交预约申请',
  checkBoxLabel: '全选',
  detailBanner: {
    before: {
      title: '预约宜家组装服务',
      desc: '上门组装服务，专业又省心',
      diffcultDesc: '{assemblyDifficultCnt}件商品组装难度较大，建议选择组装服务'
    },
    after: {
      title: '您已预约宜家组装服务',
      actions: {
        my_assembly_orders: '我的预约',
        assembly_detail: '服务介绍'
      }
    },
  },
  childItemCntLabel: '包含{payload}件商品',
  bookingsubmit: {
    status: {
      processing: '预约中...',
      success: '申请成功'
    }
  },
  prodCard: {
    priceDesc: '安装费',
    servicePriceDesc: {
      wallAnchoringService: '上墙服务',
      curtainRoleAnchoringService: '窗帘杆上墙服务',
      electricityInstallationService: '接电服务',
      bathroomInstallationService: '浴室安装服务',
      kitchenInstallationService: '厨房安装服务',
      slidingDoorInstallationService: '移门安装服务',
      default: '组装服务费'
    },
    diffcultDesc: '组装难度较大，建议选择组装服务',
    noPriceDesc: '安装费以客服计算为准'
  },
  
  booking: {
    title: '预约宜家组装服务',
    ikea: '宜家组装服务',
    master_wan: '“万师傅”平台组装服务',
    actions: {
      booking_ikea_service: '预约宜家组装服务',
      booking_service: '预约服务'
    },
    status: {
      processing: '预约中...',
      success: '预约成功'
    }
  },
  booked: {
    title: '宜家组装服务预约成功',
    desc: '您的预约已收到，客服人员会尽快与您联系，办理下列商品的组装服务'
  },
  patService: {
    netAmount: '组装服务费',
    bookedServiceTitle: '已订购的宜家组装服务',
    expectedTitle: '预计上门组装时间',
    selectedTitle: '已选择需要组装服务的商品',
    installationFee: '安装费',
    basicFee: '基础服务费',
    moreInfo: '了解收费明细',
    includes: '包含{childItemsCount}件商品',
    status: {
      CREATED: '服务待上门',
      PROCESSED: '服务待上门',
      CANCELED: '服务已取消',
      COMPLETED: '服务已完成',
    }
  },
  vendors: {
    ikea: [
      {
        title: '组装收费说明：',
        desc: '该服务由宜家的服务供应商提供'
      }, {
        title: '组装服务',
        desc: '59元起'
      }, {
        title: '收费标准',
        desc: '展示的59元起意为宜家系统内给出的专业组装难易度与时间为5或以上时的情况。59元的费用参考计算为（基础费用49元 + 5分钟 x 每分钟2元 = 59元），部分产品除外，不含上墙服务。'
      }, {
        title: '备注',
        desc: '组装收费依照宜家系统内给出预估产品组装难易度和专业组装时间而定。专业组装时间为中位数，可能与实际组装用时略有差异。'
      },
      {
        title: '上墙服务',
        desc: '30元每件（购买组装服务的墙柜家具产品不加收额外上墙费用）'
      }
    ],
    wan: [
      {
        title: '自助预约流程：',
        desc: '该服务由宜家合作的共享服务平台“万师傅”提供（仅针对家具类商品）',
      },
      {
        title: '第一步',
        desc: '完成商品支付。'
      },
      {
        title: '第二步',
        desc: '打开“万师傅家庭版”微信小程序，进入“宜家客户家具组装服务”专区。',
        qrcode: '扫描左边二维码（长按保存图片），或在微信小程序搜索“万师傅家庭版”。'
      },
      {
        title: '第三步',
        desc: '添加需要组装的家具，发布任务。'
      },
      {
        title: '第四步',
        desc: '选择师傅，支付费用。'
      }
    ],
    actions: {
      more: '详细服务与收费信息',
    }
  },
  rescheduleTips: {
    title: '组装服务改期提醒',
    content: '如果您另外订购了组装服务，请联系客服更改组装服务的预约时间',
  },
  scanGo: {
    title: '请在下单后预约付费组装服务',
    desc: '若您需要组装服务，可前往一楼顾客服务区7-8号柜台预约办理。',
    stores: {
      '401': {
        desc: '若您需要组装服务，可前往2楼退换货柜台预约办理'
      },
    }
  },
  offline: {
    relatedIkeaOrderNoLabel: '订单号：',
    relatedIkeaOrderNoLabelCopied: '已复制订单号',
    relatedIkeaOrderNoLabelCopyFailed: '复制订单号失败，请重试',
    statusLabel: '服务状态',
  }
}
