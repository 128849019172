import bridge from '@/bridge'

export const setEvar = (props) => {
  const utmProps = {}

  Object.getOwnPropertyNames(props).forEach(function(key) {
    if (props[key]) utmProps[key] = props[key]
  });

  if (utmProps.utmSource_evar || utmProps.utmCampaign_evar || utmProps.utmMedia_evar) {
    utmProps.utmContent_evar = utmProps.utmContent_evar || ''
    utmProps.utmTerm_evar = utmProps.utmTerm_evar || ''
  }

  if (Object.keys(utmProps).length > 0) {
    console.log('setEvar:', utmProps)
    bridge.tracking.gio('evar.set', utmProps)
  } else {
    console.log('window.gio:', window.gio)
    console.log('utmProps:', utmProps)
  }
}
