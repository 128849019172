const getDefaultState = () => {
  return {
    data: null,
    reasonSelectorShow: false, // 取消理由的Modal Show
    confirmModalShow: false, // 二次确认的展示
    cancelClickFrom: "", // 区分是从列表页还是详情页点击的，用于控制IModal的尺寸
    needAddToShoppingCart: true, // 取消订单时默认加购物车
    detailModalShow: false, // 取消申请处理中的详情Modal（Auto Cancellation）
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateCancellation(state, newState) {
      state = newState
    },
    resetCancellation(state) {
      Object.assign(state, getDefaultState())
    },
    updateReasonSelectorShow(state, payload) {
      state.reasonSelectorShow = payload
    },
    updateCancelClickFrom(state, payload) {
      state.cancelClickFrom = payload
    },
    updateConfirmModalShow(state, payload) {
      state.confirmModalShow = payload
    },
    updateDetailModalShow(state, payload) {
      state.detailModalShow = payload
    },
    updateNeedAddToShoppingCart(state, payload) {
      state.needAddToShoppingCart = payload
    }
  },
  actions: {

  }
}
