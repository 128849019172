// O2O实现
import abtest from './abtest'
import addNavigationActions from './addNavigationActions'
import bridge from './bridge'
import currentLanguage from './currentLanguage'
import currentPlatform from './currentPlatform'
import getNotificationPermission from './getNotificationPermission'
import getToken from './getToken'
import getUserInfo from './getUserInfo'
import goToPayment from './goToPayment'
import goToPipInfo from './goToPipInfo'
import greeting from './greeting'
import init from './init'
import isLogin from './isLogin'
import navigateBack from './navigateBack'
import navigateTo from './navigateTo'
import openH5 from './openH5'
import openMap from './openMap'
import openSetting from './openSetting'
import postMessage from './postMessage'
import request from './request'
import tracking from './tracking'

export default {
  abtest,
  greeting,
  currentLanguage,
  currentPlatform,
  isLogin,
  navigateTo,
  getUserInfo,
  init,
  request,
  postMessage,
  tracking,
  openH5,
  goToPayment,
  goToPipInfo,
  navigateBack,
  addNavigationActions,
  openMap,
  bridge,
  getToken,
  openSetting,
  getNotificationPermission
}
