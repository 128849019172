export default {
  title: 'Select Delivery Time',
  select: 'Select Delivery Time',
  selected: 'Selected {period}',
  rule: {
    name: 'Reschedule Rule',
    title: 'Reschedule Rule',
  },
  action_sheet: {
    before_sorting: {
      title: 'Before Order Picking',
      desc: 'You can modify the delivery date by yourself in the order details page. (Subject to the actual operation result)',
    },
    after_sorting: {
      title: 'After Order Picking',
      desc: 'If you still need to reschedule, you can change the date to a date within seven days of the delivery date, please contact customer service one day before the original delivery date (the date agreed between you and IKEA customer service will prevail). Please understand that IKEA is not responsible for product storage and will refund you if the product is not delivered after the expiration date.',
    }
  },
  status: {
    success: 'Modification Successful',
    failed: 'Modification Failed'
  }
}
