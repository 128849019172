<template>
  <span
    v-if="refStatus"
    :class="
      `${classNames('order-status',
        `order-status__${refStatus.toLowerCase()}`, {
        'order-status-debug': debug
      })}`
    "
  >
    {{ debug
      ? {
        remain,
        deliveryOption,
        hasReviewed,
        hasExceededReviewTimeLimit,
        isSimpleServiceOrder,
        status,
        refStatus,
        refStatusText,
      }
      : refStatusText
    }}
  </span>
</template>

<script>
import { defineComponent, ref } from 'vue'
import classNames from 'classnames'
import { computeStatus } from './utils'

export default defineComponent({
  props: {
    debug: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'WAITING_FOR_PAYMENT'
    },
    remain: {
      type: Number,
      default: 0
    },
    deliveryOption: {
      type: String,
      default: ''
    },
    hasReviewed: {
      type: Boolean,
      default: false,
    },
    hasExceededReviewTimeLimit: {
      type: Boolean,
      default: false,
    },
    isSimpleServiceOrder: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'plain'
    }
  },
  setup(props) {
    const computed = computeStatus({ ...props })
    const refStatus = ref(computed.status)
    const refStatusText = ref(computed.statusText)
    return {
      classNames,
      refStatus,
      refStatusText
    }
  },
})
</script>

<style lang="less" scoped>
.order-status {
  font-size: .875rem;
  &__waiting_for_payment {
    color: #E00751;
  }
  &__post_review {
    color: #0A8A00;
  }
  &-debug {
    font-size: 12px;
    color: #757575;
  }
}
</style>
