<template>
  <div :class="`${classNames('tag', `tag--${ cssClass }`)}`">
    <slot name="name"></slot>
  </div>
</template>

<script>
// import bridge from '@/bridge'
import { defineComponent, onMounted, ref, computed } from 'vue'
import classNames from 'classnames'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
  },
  props: {
    class: {
      type: String,
      default: ''
    }
  }, 
  setup(props) {
    const cssClass = computed(() => props.class)
    const store = useStore()
    const { globalState, trackAndTraceState } = store.state
    return {
      cssClass,
      classNames
    }
  },
})
</script>

<style lang="less" scoped>
.tag {
  display: inline;
  font-size: 10px;
  border-radius: 2px;
  padding: 2px 4px;
  margin: 0 4px;

  &--green-energy {
    background-color: #EAF9E9;
    color: #0A8A00;
  }
}
</style>
