export default {
  notes: {
    processing: 'The order is processing'
  },
  actions: {
    viewProgress: {
      text: 'Aftersale progress'
    }
  },
  statusPage: {
    title: 'Aftersale Status',
    actions: {
      iknow: {
        text: 'I got it'
      }
    },
    status_enums: {
      SELF_SERVICE_RETURN: {
        CREATED: {
          title: 'Pending',
          desc: 'Your refund  request has been accepted. We will handle your question within 48 hours. Please wait patiently.',
        },
        PROCESSING: {
          title: 'Processing',
          desc: 'Your refund requirement has been handled by our specialist，please pay attention to your phone message and the result will be sent to your phone message inbox within 48 hours.',
        },
        COMPLETED: {
          title: 'Completed',
          desc: 'Your after-sales requirement have been handled, please follow the communication result with IKEA service staff, thank you for choosing IKEA.',
        },
      },
      SELF_SERVICE_EXCHANGE: {
        CREATED: {
          title: 'Pending',
          desc: 'Your exchange request has been accepted. We will handle your problem within 48 hours. Please wait patiently.',
        },
        PROCESSING: {
          title: 'Processing',
          desc: 'Your exchange requirement has been handled by our specialist，please pay attention to your phone message and the result will be sent to your phone message inbox within 48 hours.',
        },
        COMPLETED: {
          title: 'Completed',
          desc: 'Your after-sales requirement have been handled, please follow the communication result with IKEA service staff, thank you for choosing IKEA.',
        },
      }
    },
  },
  wxLiveStream: {
    headline: 'Please navigate to wechat channels order page for after sales',
    details: 'Please navigate to[wechat--channels--view settings--orders] for after sales',
    details1: 'There is a little delay in order status, please wait',
  }
}
