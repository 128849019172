<template>
  <div class="service-card-wrapper">
    <van-card>
      <template #title>
        <h4>{{item.serviceName}}</h4>
      </template>
      <template #desc>
        <ik-notice v-if="item.serviceTime">
          <template #label>
            {{$t('message.order_detail.service.estimated_service_time')}}
          </template>
          <template #content>
            <ik-time-period :from="item.serviceFromTime" :to="item.serviceTime" />
          </template>
        </ik-notice>
        <ik-card
          :folded="isFolded"
        >
          <template #header v-if="
            item.serviceReferenceItems
            && item.serviceReferenceItems.length
          ">
            <ik-space-between-cell>
              <template #label>
                {{$t('message.order_detail.service.product')}}
              </template>
              <template #value v-if="toggler">
                <a
                  :class="`toggler toggler-${isFolded ? 'folded' : 'unfolded'}`"
                  @click="handleClick"
                >
                  {{
                    isFolded
                      ? lang.message.order_detail.service.actions.unfold
                      : lang.message.order_detail.service.actions.fold
                  }}
                </a>
              </template>
            </ik-space-between-cell>
          </template>
          <template #main>
            <div
              class="products-with-service-list"
              v-if="
                item.serviceReferenceItems
                && item.serviceReferenceItems.length
              "
            >
              <ik-prod-card
                v-for="(p, i) in item.serviceReferenceItems"
                v-bind:key="i"
                :item="p ?? {}"
                :hasPrice="false"
                size="small"
                :order="order"
              ></ik-prod-card>
            </div>
          </template>
          <template #footer v-if="item.netAmount">
            <ik-space-between-cell>
              <template #label>
                {{$t('message.order_detail.service.price')}}
              </template>
              <template #value>
                <ik-price
                  :price="item.netAmount"
                />
              </template>
            </ik-space-between-cell>
          </template>
        </ik-card>
      </template>
    </van-card>
  </div>
</template>

<script>
import moment from 'moment'
import { defineComponent, ref } from 'vue'
import IkSpaceBetweenCell from '../SpaceBetween/cell.vue'
import IkProdCard from '../ProdCard'
import IkPrice from '../Price'
import IkCard from '../Card'
import IkNotice from '../Notice'
import IkTimePeriod from '../TimePeriod'
import { lang } from '../../../locales'
import Cookies from 'js-cookie';
import { useStore } from 'vuex'

moment.locale(Cookies.get('ikeacn_lang') === 'en' ? 'en' : 'zh-cn')

export default defineComponent({
  components: {
    IkSpaceBetweenCell,
    IkProdCard,
    IkPrice,
    IkCard,
    IkNotice,
    IkTimePeriod,
  },
  props: {
    item: {
      type: Object,
    },
    toggler: {
      type: Boolean,
      default: false
    },
    folded: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const store = useStore()
    const { globalState } = store.state

    const isFolded = ref(props.folded)
    
    // const item = {
    //   itemNO: '70215530',
    //   itemType: 'ART',
    //   itemName: 'FABLER BJÖRN 费布勒 比约',
    //   itemPicture: 'https://www.ikea.cn/cn/zh/images/products/fabler-bjoern-fei-bu-le-bi-yue-mao-rong-wan-ju-mi-huang-se__0710165_pe727396_s3.jpg',
    //   quantity: 5,
    //   salesPrice: 3.9,
    //   listPrice: 3.9,
    //   vtpItem: null,
    //   lineId: 1,
    //   childItems: null,
    //   validDesignText: '米黄色',
    //   productTypeName: '毛绒玩具',
    //   hasAssembleService: false,
    //   vtpDesignID: null
    // }

    const handleClick = () => {
      isFolded.value === true
        ? isFolded.value = false
        : isFolded.value = true

      // https://jira.digital.ingka.com/browse/CNODI-2154 AC07
      // 去掉埋点：figma上是弹窗，以我们order-detail页面的交互设计为准
      // if (isFolded.value === false) {
      //   if (globalState.isFromScanGo && globalState.extras.autoJump) {
      //     track(trackingConf.eventName.expose, {
      //       eventType_var: 'impression',
      //       platform_var: 'SC',
      //       pageType_var: trackingConf.exp.order_detail,
      //       pageName_var: '订单详情页',
      //       pageId_var: props.order.orderNO
      //     })
      //   }
      // }
    }

    return {
      lang,
      moment,
      isFolded,
      // item,
      handleClick,
    }
  },
})
</script>

<style lang="less" scoped>
.van-card {
  --van-card-padding: 0;

  .toggler {
    color: #0058A3;
    font-size: 14px;
    cursor: pointer;
    &::after {
      content: '';
      display: inline-block;
      background-image: url('../../../assets/icons/icon_arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      width: 11px;
      height: 11px;
      margin-left: 4px;
      transition-property: transform;
      transition-duration: .3s;
    }

    &-folded {
      &::after {
        transform: translateY(.5px) rotate(540deg);
      }
    }

    &-unfolded {
      &::after {
        transform: translateY(.5px) rotate(0deg);
      }
    }
  }

}
</style>
