<template>
  <div :class="`${classNames('card-wrapper', {
    'card-wrapper-folded': folded,
    'card-wrapper-unfolded': !folded,
  })}`">
    <div class="card-header">
      <slot name="header"></slot>
    </div>
    <div class="card-body">
      <slot name="main"></slot>
    </div>
    <div class="card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import classNames from 'classnames'

export default defineComponent({
  props: {
    toggler: {
      type: Boolean,
      default: true,
    },
    folded: {
      type: Boolean,
      default: true
    }
  },
  components: {
  },
  setup() {
    return {
      classNames,
    }
  },
})
</script>

<style lang="less" scoped>
.card-wrapper {
  position: relative;
  border-radius: inherit;
  .card-header, .card-footer {
    border-radius: inherit;
  }

  .card-body {
    transition-property: max-height;
    transition-duration: .3s;
    transition-delay: 0s;
    overflow: hidden;
    max-height: 100vh;
  }

  &-unfolded {
    .card-body {
      max-height: 100vh;
    }
  }

  &-folded {
    .card-body {
      max-height: 0vh;
    }
  }
}
</style>
