import afterSales from './after-sales'
import assembly_service from './assembly-service'
import delivery from './delivery'
import discounts_popup from './discounts-popup'
import order from './order'
import orderActions from './orderActions'
import orderStatus from './orderStatus'
import product from './product'
import store from './store'

export default {
  ...order,
  title: '订单详情',
  status: {
    ...orderStatus,
    waiting_for_payment: `${orderStatus.waiting_for_payment}`,
    canceled: `订单${orderStatus.canceled}`,
    completed: `订单${orderStatus.completed}`,
  },
  actions: {
    ...orderActions,
    fold: '收起',
    unfold: '展开',
  },
  count_down_template: {
    prefix: '',
    suffix: '后自动取消，待支付金额',
    format1: 'mm分ss秒',
    format2: 'HH时mm分ss秒',
    scan_go_by_csc_store: '将于当天24时自动取消，请尽快支付',
  },
  product,
  delivery,
  assembly_service,
  reload: '重新加载',
  service_down: '服务器开小差，请稍后再试',
  discounts_popup,
  store,
  offline: {
    ikeaOrderNo: {
      label: 'Isell订单号'
    }
  },
  afterSales: {
    ...afterSales
  },
  err: {
    forbidden_to_order: {
      title: '订单已完成支付',
      desc: '此订单无需再次支付，请和支付用户核对，如有疑问请咨询商场员工'
    },
    forbidden_scango_cancelled_order: {
      title: '订单已取消',
      desc: '此订单已取消，不支持查看，如有疑问请咨询商场工作人员'
    },
    forbidden_scango_paid_order: {
      title: '订单已完成支付',
      desc: '此订单已支付，不支持查看，如有疑问请咨询商场工作人员'
    }
  },
  feedback: {
    title: '请告诉我们你对本次购物的想法或建议',
    desc: '留下您的宝贵意见，帮助我们不断改进和提升服务质量',
    btnText: '告诉我们反馈',
    btnTip: '得50会员积分'
  },
  receivedPoints: '{receivedPoints}会员积分已到账',
  locker: {
    lockerNo: '货柜号',
    pickupNo: '取货号',
    pickupLocation: '请前往{lockerLocation}自提柜，扫码或输入取货码取货'
  }
}
