const store = {
  '058': {
    name: '天津东丽商场',
    addr: '东丽区津塘公路433号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=4a83c06f9e4211eab6c81b27a8574253',
    cmsId: '4a83c06f9e4211eab6c81b27a8574253'
  },
  '164': {
    name: '无锡商场',
    addr: '锡山区团结中路1号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=1c5115a1a16b11eab3f08dec0649fce5',
    cmsId: '1c5115a1a16b11eab3f08dec0649fce5'
  },
  '214': {
    name: '北京西红门商场',
    addr: '大兴区欣宁街15号院2号楼',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=dcc575b5a1a711ea88dd71c4bc57528c',
    cmsId: 'dcc575b5a1a711ea88dd71c4bc57528c'
  },
  '247': {
    name: '上海宝山商场',
    addr: '宝山区沪太路1818号(近汶水路)',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=8497a449a3b511eaa5056f153dce146c',
    cmsId: '8497a449a3b511eaa5056f153dce146c'
  },
  '279': {
    name: '宁波商场',
    addr: '鄞州区宜园路525号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=e99b49e9a54b11eaa5056f153dce146c',
    cmsId: 'e99b49e9a54b11eaa5056f153dce146c'
  },
  '330': {
    name: '重庆商场',
    addr: '渝北区汇流路2号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=f3ce563c9fbe11eab3f08dec0649fce5',
    cmsId: 'f3ce563c9fbe11eab3f08dec0649fce5'
  },
  '401': {
    name: '杭州商场',
    addr: '余杭区乔司街道乔莫西路5号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=010af166a18811ea88dd71c4bc57528c',
    cmsId: '010af166a18811ea88dd71c4bc57528c'
  },
  '424': {
    name: '西安商场',
    addr: '沣东新城三桥路866号(三桥立交东南角)',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=653e2cc0a3d011eaa5056f153dce146c',
    cmsId: '653e2cc0a3d011eaa5056f153dce146c'
  },
  '459': {
    name: '佛山商场',
    addr: '南海区桂城街道桂澜北路18号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=9603a67da70811ea935571269bb24132',
    cmsId: '9603a67da70811ea935571269bb24132'
  },
  '571': {
    name: '贵阳商场',
    addr: '观山湖区金清大道2号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=44c89e42a84c11e9863f07c37ec6982d',
    cmsId: '44c89e42a84c11e9863f07c37ec6982d'
  },
  '485': {
    name: '哈尔滨商场',
    addr: '南岗区复旦街48号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=8c8d2535a3e511eaa5056f153dce146c',
    cmsId: '8c8d2535a3e511eaa5056f153dce146c'
  },
  '558': {
    name: '天津中北商场',
    addr: '西青区中北镇万卉路7号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=71debdf49fc811eab3f08dec0649fce5',
    cmsId: '71debdf49fc811eab3f08dec0649fce5',
  },
  '521': {
    name: '济南商场',
    addr: '槐荫区烟台路121号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=624b30a0a60a11eaa5056f153dce146c',
    cmsId: '624b30a0a60a11eaa5056f153dce146c'
  },
  '495': {
    name: '大连商场',
    addr: '西岗区海达南街51号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=7ebd24b9a3e311eaa5056f153dce146c',
    cmsId: '7ebd24b9a3e311eaa5056f153dce146c'
  },
  '544': {
    name: '广州番禺商场',
    addr: '番禺区东艺路139号-15(新光快速东艺路出口直达)',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=717f5119a63811eaa5056f153dce146c',
    cmsId: '717f5119a63811eaa5056f153dce146c'
  },
  '584': {
    name: '广州天河商场',
    addr: '天河区前进街道黄埔大道东663号美林M·LIVE天地F2',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=7d4f2a5885d111e983562d7c0d6a8721',
    cmsId: '7d4f2a5885d111e983562d7c0d6a8721'
  },
  '466': {
    name: '成都高新商场',
    addr: '高新区益州大道北段(近天府立交)',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=62231f29a22511eaa5056f153dce146c',
    cmsId: '62231f29a22511eaa5056f153dce146c'
  },
  '481': {
    name: '南京商场',
    addr: '秦淮区明匙路99号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=5b90aaf19e3e11eab6c81b27a8574253',
    cmsId: '5b90aaf19e3e11eab6c81b27a8574253'
  },
  '418': {
    name: '成都成华商场',
    addr: '成华区蓉都大道将军碑路9号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=d9943569a3ad11eaa5056f153dce146c',
    cmsId: 'd9943569a3ad11eaa5056f153dce146c'
  },
  '484': {
    name: '苏州商场',
    addr: '高新区浒墅关镇城际路17号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=be2ac450a3ea11eaa5056f153dce146c',
    cmsId: 'be2ac450a3ea11eaa5056f153dce146c'
  },
  '512': {
    name: '南通商场',
    addr: '港闸区黄海路66号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=a33828f0a6f011ea93a0019f9b8418aa',
    cmsId: 'a33828f0a6f011ea93a0019f9b8418aa'
  },
  '340': {
    name: '武汉商场',
    addr: '硚口区张毕湖路2号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=ebcf5569a0c011eab3f08dec0649fce5',
    cmsId: 'ebcf5569a0c011eab3f08dec0649fce5'
  },
  '568': {
    name: '徐州商场',
    addr: '云龙区和平路128号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=091a7f0ba3de11eaa5056f153dce146c',
    cmsId: '091a7f0ba3de11eaa5056f153dce146c'
  },
  '572': {
    name: '郑州商场',
    addr: '惠济区长兴路2号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=cfdb19afa3a711eaa5056f153dce146c',
    cmsId: 'cfdb19afa3a711eaa5056f153dce146c'
  },
  '581': {
    name: '长沙商场',
    addr: '岳麓区洋湖路253号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=38cda8e69fe911eab3f08dec0649fce5',
    cmsId: '38cda8e69fe911eab3f08dec0649fce5'
  },
  '833': {
    name: '深圳商场',
    addr: '南山区北环大道8188号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=92b77634835011e983562d7c0d6a8721',
    cmsId: '92b77634835011e983562d7c0d6a8721'
  },
  '585': {
    name: '上海杨浦商场',
    addr: '三门路99号1层112室',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=3284b1aaa3d511eaa5056f153dce146c',
    cmsId: '3284b1aaa3d511eaa5056f153dce146c'
  },
  '601': {
    name: '青岛商场',
    addr: '崂山区深圳路100号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=fae80220937f11eab002599e2b3fb8c1',
    cmsId: 'fae80220937f11eab002599e2b3fb8c1'
  },
  '621': {
    name: '昆明商场',
    addr: '五华区王筇路168号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=0f9c9470eea011eb909ec36c6e99b004',
    cmsId: '0f9c9470eea011eb909ec36c6e99b004'
  },
  '624': {
    name: '福州商场',
    addr: '晋安区福马路788号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=25351730f32411eab15e43097300dec7',
    cmsId: '25351730f32411eab15e43097300dec7'
  },
  '630': {
    name: '南宁商场',
    addr: '良庆区平乐大道12号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=4d67e040fc1811eb909ec36c6e99b004',
    cmsId: '4d67e040fc1811eb909ec36c6e99b004'
  },
  '644': {
    name: '上海静安城市店',
    addr: '静安区南京西路1728号百乐门大都会1-3楼',
    type: 'EXPERIENCE_CENTER',
    url: 'https://ikeaapp/cms/page?id=599c7490bd1511ea928217435a65a858',
    cmsId: '599c7490bd1511ea928217435a65a858'
  },
  '802': {
    name: '北京四元桥商场',
    addr: '朝阳区阜通东大街59号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=375a0b02a18d11ea88dd71c4bc57528c',
    cmsId: '375a0b02a18d11ea88dd71c4bc57528c'
  },
  '856': {
    name: '上海徐汇商场',
    addr: '徐汇区漕溪路126号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=8d205890a3ca11eaa5056f153dce146c',
    cmsId: '8d205890a3ca11eaa5056f153dce146c'
  },
  '885': {
    name: '上海北蔡商场',
    addr: '浦东新区北蔡镇临御路550号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=3e6e4980b7f011e9863f07c37ec6982d',
    cmsId: '3e6e4980b7f011e9863f07c37ec6982d'
  },
  '886': {
    name: '沈阳商场',
    addr: '铁西区兴华北街20号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=51616d72a3e111eaa5056f153dce146c',
    cmsId: '51616d72a3e111eaa5056f153dce146c'
  },
  '21401': {
    name: '北京五棵松体验中心',
    addr: '海淀区复兴路69号院2号华熙LIVE负一层001室',
    type: 'EXPERIENCE_CENTER',
    url: 'https://ikeaapp/cms/page?id=67f936bdc32611e9a11337c41edc6b54',
    cmsId: '67f936bdc32611e9a11337c41edc6b54'
  },
  '27901': {
    name: '温州体验中心',
    addr: '瓯海区温瑞大道999号华润万象城1层商业街',
    type: 'EXPERIENCE_CENTER',
    url: 'https://ikeaapp/cms/page?id=2bd3b578a48211eaa5056f153dce146c',
    cmsId: '2bd3b578a48211eaa5056f153dce146c'
  },
  '957': {
    name: '明日之店',
    addr: '徐汇区漕溪路126号',
    type: 'STANDARD',
    url: 'https://ikeaapp/cms/page?id=8d205890a3ca11eaa5056f153dce146c',
    cmsId: '8d205890a3ca11eaa5056f153dce146c'
  },
}

export default store

// 058    天津东丽商场    IKEA Tianjin    STANDARD    https://ikeaapp/cms/page?id=4a83c06f9e4211eab6c81b27a8574253
// 164    无锡商场    IKEA Wuxi    STANDARD    https://ikeaapp/cms/page?id=1c5115a1a16b11eab3f08dec0649fce5
// 214    北京西红门商场    IKEA Beijing - Xihongmen    STANDARD    https://ikeaapp/cms/page?id=dcc575b5a1a711ea88dd71c4bc57528c
// 247    上海宝山商场    IKEA Shanghai - Baoshan    STANDARD    https://ikeaapp/cms/page?id=8497a449a3b511eaa5056f153dce146c
// 279    宁波商场    IKEA Ningbo    STANDARD    https://ikeaapp/cms/page?id=e99b49e9a54b11eaa5056f153dce146c
// 330    重庆商场    IKEA Chongqing    STANDARD    https://ikeaapp/cms/page?id=f3ce563c9fbe11eab3f08dec0649fce5
// 401    杭州商场    IKEA Hangzhou    STANDARD    https://ikeaapp/cms/page?id=010af166a18811ea88dd71c4bc57528c
// 424    西安商场    IKEA Xi'an    STANDARD    https://ikeaapp/cms/page?id=653e2cc0a3d011eaa5056f153dce146c
// 459    佛山商场    IKEA Guangzhou - Foshan    STANDARD    https://ikeaapp/cms/page?id=9603a67da70811ea935571269bb24132
// 571    贵阳商场    IKEA Guiyang - Guanshanhu    STANDARD    https://ikeaapp/cms/page?id=44c89e42a84c11e9863f07c37ec6982d
// 485    哈尔滨商场    IKEA Harbin    STANDARD    https://ikeaapp/cms/page?id=8c8d2535a3e511eaa5056f153dce146c
// 558    天津中北商场    IKEA Tianjin - Zhongbei    STANDARD    https://ikeaapp/cms/page?id=71debdf49fc811eab3f08dec0649fce5
// 521    济南商场    IKEA Jinan    STANDARD    https://ikeaapp/cms/page?id=624b30a0a60a11eaa5056f153dce146c
// 495    大连商场    IKEA Dalian - Xianglujiao    STANDARD    https://ikeaapp/cms/page?id=7ebd24b9a3e311eaa5056f153dce146c
// 544    广州番禺商场    IKEA Guangzhou - Panyu    STANDARD    https://ikeaapp/cms/page?id=717f5119a63811eaa5056f153dce146c
// 584    广州天河商场    IKEA Guangzhou - Tianhe    STANDARD    https://ikeaapp/cms/page?id=7d4f2a5885d111e983562d7c0d6a8721
// 466    成都高新商场    IKEA Chengdu    STANDARD    https://ikeaapp/cms/page?id=62231f29a22511eaa5056f153dce146c
// 481    南京商场    IKEA Nanjing - Qinhuai    STANDARD    https://ikeaapp/cms/page?id=5b90aaf19e3e11eab6c81b27a8574253
// 418    成都成华商场    IKEA Chengdu - Chenghua    STANDARD    https://ikeaapp/cms/page?id=d9943569a3ad11eaa5056f153dce146c
// 484    苏州商场    IKEA Suzhou store    STANDARD    https://ikeaapp/cms/page?id=be2ac450a3ea11eaa5056f153dce146c
// 512    南通商场    IKEA Nantong    STANDARD    https://ikeaapp/cms/page?id=a33828f0a6f011ea93a0019f9b8418aa
// 340    武汉商场    IKEA Wuhan - Qiaokou    STANDARD    https://ikeaapp/cms/page?id=ebcf5569a0c011eab3f08dec0649fce5
// 568    徐州商场    IKEA Xuzhou - Jing Kai    STANDARD    https://ikeaapp/cms/page?id=091a7f0ba3de11eaa5056f153dce146c
// 572    郑州商场    IKEA Zhengzhou - Huiji    STANDARD    https://ikeaapp/cms/page?id=cfdb19afa3a711eaa5056f153dce146c
// 581    长沙商场    IKEA Changsha - Yuelu    STANDARD    https://ikeaapp/cms/page?id=38cda8e69fe911eab3f08dec0649fce5
// 833    深圳商场    IKEA Shenzhen - Nanshan    STANDARD    https://ikeaapp/cms/page?id=92b77634835011e983562d7c0d6a8721
// 585    上海杨浦商场    IKEA Shanghai - Yangpu    STANDARD    https://ikeaapp/cms/page?id=3284b1aaa3d511eaa5056f153dce146c
// 601    青岛商场    IKEA Qingdao    STANDARD    https://ikeaapp/cms/page?id=fae80220937f11eab002599e2b3fb8c1
// 624    福州商场    IKEA Fuzhou    STANDARD    https://ikeaapp/cms/page?id=25351730f32411eab15e43097300dec7
// 644    上海静安城市店    IKEA Shanghai - Jingan    EXPERIENCE_CENTER    https://ikeaapp/cms/page?id=599c7490bd1511ea928217435a65a858
// 802    北京四元桥商场    IKEA Beijing - Siyuanqiao    STANDARD    https://ikeaapp/cms/page?id=375a0b02a18d11ea88dd71c4bc57528c
// 856    上海徐汇商场    IKEA Shanghai - Xuhui (Upgrading)    STANDARD    https://ikeaapp/cms/page?id=8d205890a3ca11eaa5056f153dce146c
// 885    上海北蔡商场    IKEA Shanghai - Beicai    STANDARD    https://ikeaapp/cms/page?id=3e6e4980b7f011e9863f07c37ec6982d
// 886    沈阳商场    IKEA Shengyang - Tie Xi    STANDARD    https://ikeaapp/cms/page?id=51616d72a3e111eaa5056f153dce146c
// 21401    北京五棵松体验中心    IKEA Beijing -  Wukesong Experience Centre    EXPERIENCE_CENTER    https://ikeaapp/cms/page?id=67f936bdc32611e9a11337c41edc6b54
// 27901    温州体验中心    IKEA Wenzhou Experience Centre    EXPERIENCE_CENTER    https://ikeaapp/cms/page?id=2bd3b578a48211eaa5056f153dce146c
// 957    明日之店    IKEA Store of Tomorrow    STANDARD    https://ikeaapp/cms/page?id=8d205890a3ca11eaa5056f153dce146c
