// import { EOrderStatus, OrderChannel_Enums } from '@/enums/order'
import { yhtService } from '@/service'
import * as Sentry from '@sentry/vue'
// import { useScriptTag } from "@vueuse/core";
// import { remove } from 'lodash'
// import { Transport_Method_NAME } from '@/enums/track-and-trace'
// import { lang } from '@/locales'

const GEE_TEST_SDK_URL =
  "https://static.web.ikea.cn/static/js/gt.js";

export interface KV {
  [key: string]: any
}

export interface ITrackAndTraceInfo {
  code: string;
  data: unknown;
}

export interface IFirstRegisterState {
  GEE_TEST_SDK_URL: string;
  firstRegisterInfo: KV | null;
  pageLoading: boolean;
}

const getDefaultState = (): IFirstRegisterState => {
  return {
    GEE_TEST_SDK_URL,
    firstRegisterInfo: null,
    pageLoading: true,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updatePageLoading(state: IFirstRegisterState, payload: boolean) {
      state.pageLoading = payload
    },
    updateFirstRegisterInfo(state: IFirstRegisterState, payload: ITrackAndTraceInfo) {
      state.firstRegisterInfo = payload
    },
  },
  actions: {
    async firstRegister({ commit }, payload) {
      try {
        const firstRegisterInfo = await yhtService.firstRegister()
        // console.log('== firstRegisterInfo ==', firstRegisterInfo)
        commit('updateFirstRegisterInfo', firstRegisterInfo.data)
        commit('updatePageLoading', false)

        return firstRegisterInfo.data
      } catch (e) {
        Sentry.captureException(new Error(JSON.stringify(e)));
      }
    },
    async getAppDownloadGuide({ commit }) {
      const appDownloadGuideInfo = await yhtService.getAppDownloadGuide()
      return appDownloadGuideInfo
    },
  }
}
