import { Origin_Enums } from '@/enums'
import qs from 'qs'
import ikea from './ikea'
import scanGo from './scan-go'

// 渠道硬编码
const query = qs.parse(location.search.replace(/^\?(.*)/, '$1'))

const bridge = () => {
  switch (query?.origin) {
    case Origin_Enums.SCAN_GO:
      scanGo.tracking.init = scanGo.tracking.init.bind(scanGo.tracking, { useNative: false, projectId: "8d2cde843d1230be" })
      return scanGo
    default:
      return ikea
  }
}

export default bridge()
