<template>
  <div class="price-wrapper" :style="style">
    <i
      :class="`${className('polar', size)}`"
      v-if="slots.polar"
    >
      <slot name="polar" :price="price"></slot>
    </i>
    <sup
      :class="`${className('symbol', size)}`" v-if="slots.symbol">
      <slot name="symbol" :price="price"></slot>
    </sup>
    <div :class="`${className('ints', size)}`">
      <slot name="ints" :price="price"></slot>
    </div>
    <sup :class="`${className('decimal', size)}`">
      <slot name="decimal" :price="price"></slot>
    </sup>
    <sup :class="`${className('cents', size)}`">
      <slot name="cents" :price="price"></slot>
    </sup>
  </div>
</template>

<script>
import './price.less';
import { defineComponent, reactive } from 'vue'
import classNames from 'classnames'

export default defineComponent({
  props: ['price', 'size', 'polar', 'style'],
  setup(props, context) {
    props = reactive(props)

    const slots = context.slots

    const className = (name, size) => {
      return classNames({
        'price-part': true,
        [`price-part--${name}`]: true
      }, `price-part--${name}--${size}`)
    }

    return {
      slots,
      className
    }
  },
})
</script>
